import { useState } from "react";

const usePagination = (itemsPerPage) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [paginatedData, setPaginatedData] = useState([]);

  const appendData = (newData) => {
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const newItems = newData.slice(startIndex, endIndex);
    setPaginatedData((prevData) => [...prevData, ...newItems]);
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const resetPagination = (newData) => {
    const initialItems = newData.slice(0, itemsPerPage);
    setPaginatedData(initialItems);
    setCurrentPage(1);
  };

  return {
    paginatedData,
    appendData,
    resetPagination,
    currentPage,
    setCurrentPage,
  };
};

export default usePagination;
