import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="site-footer layout-1">
        <div className="footer-sidebars">
          <div className="container">
            <div className="thim-3-col">
              <aside className="widget widget_thim_layout_builder">
                <div className="wpb_single_image">
                  <img src="assets/images/logo.png" alt="logo" width={170} />
                </div>
                <div className="wpb_text_column">
                  <p>
                    Lommodo ligula eget dolor. Aenean massa.Cum sociis que
                    penatibus et magnis dis parturient montes lorem, nascetur
                    ridiculusmus. Donec quam felis, ultricies nec massa.Cum
                    sociis ...
                  </p>
                </div>
                <form className="yikes-easy-mc-form layout-footer">
                  <label>
                    18plus Contract Address
                    <input
                      type="text"
                      placeholder="Contract"
                      defaultValue="0x638332388E5C9F9cA3B04b750AFa9fcd045476D2"
                    />
                  </label>
                  <button type="button">COPY</button>
                  <p />
                </form>
                <div className="bp-element bp-element-social-links vblog-layout-footer">
                  <div className="wrap-element">
                    <a
                      href="https://t.me/ai18pluspresale"
                      className="social-item"
                    >
                      <i className="ions fa fa-telegram" />
                    </a>
                    <a href="#" className="social-item">
                      <i className="ion ion-social-twitter" />
                    </a>
                  </div>
                </div>
              </aside>
              <aside className="widget widget_thim_layout_builder">
                <div className="bp-element bp-element-posts vblog-layout-list-footer">
                  <div className="wrap-element">
                    <div className="heading-post">
                      <h3 className="title">TOP CREATORS</h3>
                    </div>
                    <div className="list-posts">
                      <div className="item">
                        <div className="pic">
                          <a href="./profile.html">
                            <img
                              src="assets/images/strip1.jpeg"
                              alt="IMG"
                              style={{ width: "auto", height: "100px" }}
                            />
                          </a>
                        </div>
                        <div className="text">
                          <h4 className="title">
                            <a href="./profile.html">SexyLi</a>
                          </h4>
                          <div className="date">$20,000 Earned</div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="pic">
                          <a href="./profile.html">
                            <img
                              src="assets/images/strip2.jpeg"
                              alt="IMG"
                              style={{ width: "auto", height: "100px" }}
                            />
                          </a>
                        </div>
                        <div className="text">
                          <h4 className="title">
                            <a href="./profile.html">Shakiraz</a>
                          </h4>
                          <div className="date">$19,000 Earned</div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="pic">
                          <a href="./profile.html">
                            <img
                              src="assets/images/strip3.jpeg"
                              alt="IMG"
                              style={{ width: "auto", height: "100px" }}
                            />
                          </a>
                        </div>
                        <div className="text">
                          <h4 className="title">
                            <a href="./profile.html">Alischia</a>
                          </h4>
                          <div className="date">$10,000 Earned</div>
                        </div>
                      </div>
                      <div className="item">
                        <a href="#" className="btn btn-danger btn-block">
                          VIEW LEADERBOARD
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </aside>
              <aside className="widget widget_thim_layout_builder">
                <div className="bp-element bp-element-categories layout-list-1">
                  <div className="wrap-element">
                    <h3 className="title">TOP VIEWERS</h3>
                    <ul className="list-categories">
                      <li className="cat-item">
                        <a href="#">0x........dftes</a>
                      </li>
                      <li className="cat-item">
                        <a href="#">0x........dftes</a>
                      </li>
                      <li className="cat-item">
                        <a href="#">0x........dftes</a>
                      </li>
                      <li className="cat-item">
                        <a href="#">0x........dftes</a>
                      </li>
                      <li className="cat-item">
                        <a href="#">0x........dftes</a>
                      </li>
                      <li className="cat-item">
                        <a href="#">0x........dftes</a>
                      </li>
                      <li className="cat-item">
                        <a href="#">0x........dftes</a>
                      </li>
                      <li className="cat-item">
                        <a href="#">0x........dftes</a>
                      </li>
                      <li className="cat-item">
                        <a href="#">0x........dftes</a>
                      </li>
                      <li className="cat-item">
                        <a href="#">0x........dftes</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="thim-1-col">
              <div className="copyright-text">Copyright 2024. Ai18Plus</div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
