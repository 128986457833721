import { createContext, useEffect, useState } from "react";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import {
  BrowserProvider,
  Contract,
  formatUnits,
  parseUnits,
  JsonRpcProvider,
} from "ethers";
import { message } from "antd";
import abi from "../utils/controller.json";
import tokenAbi from "../utils/token.json";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import { Allowance } from "../utils/allowance";
//import { message } from 'antd';
//import { ErrorHandling } from 'utils/errorHandling';
import { formatNumber, showAlert, searchArray } from "../utils/helpers";
const projectId = "98224d4e1aa436d5b8fea301e0ff3c53";
const mainnet = {
  chainId: 1,
  name: "Ethereum",
  currency: "ETH",
  explorerUrl: "https://etherscan.io",
  rpcUrl: "https://cloudflare-eth.com",
};

const bscTestnet = {
  chainId: 97,
  name: "Binance Smart Chain Testnet",
  currency: "BNB",
  explorerUrl: "https://testnet.bscscan.com",
  rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545",
};

const bscMainnet = {
  chainId: 56,
  name: "Binance Smart Chain",
  currency: "BNB",
  explorerUrl: "https://bscscan.com",
  rpcUrl: "https://bsc-dataseed1.binance.org",
};

// 3. Create a metadata object
const metadata = {
  name: "Ai18plus",
  description: "The first real adult coin",
  url: "https://ai18plus.com/", // origin must match your domain & subdomain
  icons: ["assets/images/icon.png"],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: "https://bsc-dataseed1.binance.org", // used for the Coinbase SDK
  defaultChainId: 56, // used for the Coinbase SDK
});

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [bscTestnet],
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

export const Context = createContext();
export const ContextProvider = ({ children }) => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [tokenBalance, setTokenBalance] = useState("0");
  const [settings, setSettings] = useState([]);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [isFan, setIsFan] = useState(false);

  const token = "0x6c206A495611B9D0b4C2b70316738cAeB01a69Cc";
  const controlContract = "0x2E1Cd653a5260669Ea7Bf1B503E953aa2a85AA7c";
  const apiURL = "http://localhost/watch/api.php";

  useEffect(() => {
    if (!token || !address) return;
    const getTokenBalance = async () => {
      try {
        let abi = [
          "function balanceOf(address _owner) public view returns (uint256)",
        ];

        const provider = new JsonRpcProvider(
          "https://data-seed-prebsc-1-s1.binance.org:8545"
        );
        // const provider = new JsonRpcProvider("https://bsc-dataseed.binance.org/");

        let contract = new Contract(token, abi, provider);
        const result = await contract.balanceOf(address);
        setTokenBalance(formatUnits(result.toString(), 18));
        // console.log(result);
      } catch (error) {
        console.log(error);
      }
    };
    getTokenBalance();
  }, [address]);

  const getSettings = async () => {
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ action: "get_settings" }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        console.error("Error:", data.error);
      } else {
        setSettings(data[0]);

        //console.log("Fetched settings:", data[0]);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    getSettings();
  }, []);

  const getUser = async (wallet) => {
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ action: "get_user_info", wallet: wallet }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        console.error("Error:", data.error);
      } else {
        setUser(data[0]);

        console.log("Fetched user:", data[0]);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    if (!isConnected) {
      setUser({});
    } else {
      getUser(address);
    }
  }, [isConnected, address]);

  const checkAllowance = async (recipient, amount) => {
    if (!isConnected) {
      // Handle not connected case
      return message.error("Please connect a wallet.");
    }
    //setLoading(true);
    try {
      const allowance = await Allowance(token, controlContract, address);
      if (
        !Number(allowance) ||
        parseFloat(formatUnits(allowance, 18)) < parseFloat(amount)
      ) {
        approve(recipient, amount);
        message.info("Please Approve to spend tokens!");
      } else {
        handlePayToken(recipient, amount);
      }
    } catch (error) {
      console.error(error);
      // setLoading(false);
      message.error("Error checking allowance");
    }
  };

  const handlePayToken = async (recipient, amount) => {
    if (!isConnected) return;
    try {
      let contract;
      setLoading(true);
      const provider = new BrowserProvider(walletProvider);
      const signer = await provider.getSigner();
      contract = new Contract(controlContract, abi, signer);
      const data = await contract.payCreator(
        recipient,
        parseUnits(amount, 18),

        { from: address }
      );
      //
      async function Pending() {
        const provider = new BrowserProvider(walletProvider);
        const result = await provider.getTransactionReceipt(data.hash);
        try {
          if (result === null) {
            setTimeout(() => {
              Pending();
            }, 2000);
          } else if (result !== null) {
            const added = await addFan(recipient, amount);
            if (added) {
              showAlert({
                title: "Subscription Successful!",
                text: "Your subscription was successful!",
                icon: "success",
                confirmButtonText: "Sounds good",
              });
              setIsFan(true);
            } else {
              showAlert({
                title: "Error",
                text: "Your payment was received but there was an error adding you as a fan. Please contact support.",
                icon: "error",
                confirmButtonText: "OK",
              });
              // setIsFan(true);
            }
            setLoading(false);
          }
        } catch (error) {
          console.log("TX ERROR", error);
          setLoading(false);
        }
      }

      setTimeout(() => {
        Pending();
      }, 2000);
    } catch (error) {
      console.log("PURCHASE ERROR", error);
      let errorMessage;
      if (error.code === 4001) {
        errorMessage = "The request was rejected!";
      } else {
        errorMessage = error.message.split('"')[1];
      }
      showAlert({
        title: "ERROR!",
        text: errorMessage,
        icon: "error",
        confirmButtonText: "OK",
      });
      setLoading(false);
    }
  };

  async function approve(recipient, amount) {
    try {
      let signer;
      let contract;

      setLoading(true);
      const provider = new BrowserProvider(walletProvider);
      signer = await provider.getSigner();
      contract = new Contract(token, tokenAbi, signer);

      const data = await contract.approve(
        controlContract,
        parseUnits(amount, 18)
      );
      console.log("APPROVED", data);
      async function PendingApprove() {
        try {
          const provider = new BrowserProvider(walletProvider);
          const result = await provider.getTransactionReceipt(data.hash);
          if (result === null) {
            setTimeout(() => {
              PendingApprove();
            }, 2000);
          } else if (result !== null) {
            handlePayToken(recipient, amount);
          }
        } catch (error) {
          setLoading(false);
        }
      }

      setTimeout(() => {
        PendingApprove();
      }, 2000);
    } catch (error) {
      console.log("APPROVE ERROR", error);
      let errorMessage;
      if (error.code == 4001) {
        errorMessage = "The request was rejected!";
      } else {
        errorMessage = error.message.split('"')[1];
      }
      showAlert({
        title: "ERROR!",
        text: errorMessage,
        icon: "error",
        confirmButtonText: "OK",
      });
      setLoading(false);
    }
  }

  const handleSubscribe = (recipient, amount) => {
    if (!amount || !recipient)
      return message.error("Invalid amount or wallet address!");

    checkAllowance(recipient, amount.toString());
  };

  const addFan = async (creator, amount) => {
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          action: "add_fan",
          creator: creator,
          fan: address,
          amount: amount,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        console.error("Error:", data.error);
      } else {
        return true;
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const debitUser = async () => {
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          action: "withdraw",
          wallet: address,
          amount: user.credits,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (!data.success) {
        console.error("Error:", data);
      } else {
        getUser(address);
        showAlert({
          title: "Successful!",
          text: "Your withdrawal was successful.",
          icon: "success",
          confirmButtonText: "Sounds good",
        });
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const withdraw = async (signature, nonce) => {
    if (!isConnected) return;
    try {
      let contract;
      setLoading(true);
      const provider = new BrowserProvider(walletProvider);
      const signer = await provider.getSigner();
      contract = new Contract(controlContract, abi, signer);
      const data = await contract.withdraw(
        address,
        parseUnits(user.credits, 18),
        nonce,
        signature,

        { from: address }
      );

      async function Pending() {
        const provider = new BrowserProvider(walletProvider);
        const result = await provider.getTransactionReceipt(data.hash);
        try {
          if (result === null) {
            setTimeout(() => {
              Pending();
            }, 2000);
          } else if (result !== null) {
            await debitUser();
            setLoading(false);
          }
        } catch (error) {
          //  console.log("TX ERROR", error);
          setLoading(false);
        }
      }

      setTimeout(() => {
        Pending();
      }, 2000);
    } catch (error) {
      //  console.log("PURCHASE ERROR", error);
      let errorMessage;
      if (error.code === 4001) {
        errorMessage = "The request was rejected!";
      } else {
        errorMessage = error.message.split('"')[1];
      }
      showAlert({
        title: "ERROR!",
        text: errorMessage,
        icon: "error",
        confirmButtonText: "OK",
      });
      setLoading(false);
    }
  };

  return (
    <Context.Provider
      value={{
        address,
        token,
        chainId,
        isConnected,
        walletProvider,
        tokenBalance,
        apiURL,
        settings,
        user,
        token,
        controlContract,
        handleSubscribe,
        isFan,
        setIsFan,
        getUser,
        withdraw,
        loading,
        setLoading,
      }}
    >
      {children}
    </Context.Provider>
  );
};
