import { ethers, JsonRpcProvider, Contract } from "ethers";
import abi from "./token.json";

export async function Allowance(tokenAddress, saleAddress, address) {
  //const provider = new JsonRpcProvider("https://bsc-dataseed1.binance.org/");
  const provider = new JsonRpcProvider(
    "https://data-seed-prebsc-1-s1.binance.org:8545"
  );
  const contract = new ethers.Contract(tokenAddress, abi, provider);
  const allowance = await contract.allowance(address, saleAddress);
  // console.log("ALLOWANCE", allowance.toString());
  return allowance.toString();
}
