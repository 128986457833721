import React, { useState, useContext, useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Context } from "../../context/contex";
import axios from "axios";
import $ from "jquery";
import {
  formatNumber,
  formatLocalTime,
  shortenAddress,
  getQueryParam,
  getPercentage,
  showAlert,
} from "../../utils/helpers";
import "../styles/post-video.css";
const now = Math.floor(Date.now() / 1000);

function EditVideo() {
  const [video, setVideo] = useState({});

  const {
    address,
    token,
    chainId,
    isConnected,
    walletProvider,
    tokenBalance,
    apiURL,
    user,
  } = useContext(Context);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [title, setTitle] = useState("");
  const [isAI, setIsAI] = useState("");
  const [isVR, setIsVR] = useState("");

  const [isPrivate, setIsPrivate] = useState("");

  const [description, setDescription] = useState("");
  const [tags, setTags] = useState("");
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [uploadVideo, setUploadVideo] = useState(null);
  const [coverPhotoPreview, setCoverPhotoPreview] = useState(null);
  const [uploadVideoPreview, setUploadVideoPreview] = useState(null);
  const [videoDurationValid, setVideoDurationValid] = useState(true);

  const [uploadProgress, setUploadProgress] = useState(0);

  const getVideo = async () => {
    let vid;
    try {
      vid = getQueryParam("vid");
      if (vid == null) return;

      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ action: "get_single_video", vid: vid }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        console.error("Error:", data.error);
      } else {
        setVideo(data[0]);
        // setTheVideo(data[0].video.toString());

        //   console.log("Fetched Video URL:", data[0].video);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    getVideo();
  }, []);

  useEffect(() => {
    if (Object.keys(video).length > 0) {
      setIsVR(video.is_vr);
      setIsAI(video.is_ai);

      setIsPrivate(video.is_private);
      setTitle(video.title);
      setDescription(video.description);
      setTags(video.tags);
      setCategory(video.category);
    }
  }, [video]);

  const getCategories = async () => {
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ action: "get_categories" }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        console.error("Error:", data.error);
      } else {
        setCategories(data);
        //  console.log("Alive Numbers:", data);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const handleRadioChange = (e) => {
    setIsVR(e.target.value);
  };
  const handlePrivacyChange = (e) => {
    setIsPrivate(e.target.value);
  };
  const handleAIChange = (e) => {
    setIsAI(e.target.value);
  };

  const handleCoverPhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCoverPhoto(file);
      setCoverPhotoPreview(URL.createObjectURL(file));
    }
  };

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("video/")) {
      const videoElement = document.createElement("video");
      videoElement.preload = "metadata";
      videoElement.onloadedmetadata = function () {
        window.URL.revokeObjectURL(videoElement.src);
        const duration = videoElement.duration;
        if (duration > 600) {
          setVideoDurationValid(false);
          setUploadVideo(null);
          setUploadVideoPreview(null);
          return showAlert({
            title: "Error!",
            text: "Please select a video that is less than 10 minutes.",
            icon: "error",
            confirmButtonText: "Ok",
          });
        } else {
          setVideoDurationValid(true);
          setUploadVideo(file);
          setUploadVideoPreview(URL.createObjectURL(file));
        }
      };
      videoElement.src = URL.createObjectURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isConnected)
      return showAlert({
        title: "Error!",
        text: "Please connect your wallet and try again.",
        icon: "error",
        confirmButtonText: "Ok",
      });

    if (!category || !title || !description || !tags) {
      showAlert({
        title: "Error!",
        text: "Please fill all required fields.",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }

    if (uploadVideo == null && !videoDurationValid) {
      showAlert({
        title: "Error!",
        text: "Please select a video that is less than 10 minutes.",
        icon: "error",
        confirmButtonText: "Ok",
      });

      return;
    }

    const vid = getQueryParam("vid");
    if (vid == null) return;

    const formData = new FormData();
    formData.append("vid", vid);
    formData.append("category", category);
    formData.append("title", title);
    formData.append("isVR", isVR);
    formData.append("isAI", isAI);

    if (user.is_creator == "yes") {
      formData.append("isPrivate", isPrivate);
    }
    if (coverPhoto != null) {
      formData.append("coverPhoto", coverPhoto);
    }
    if (uploadVideo != null) {
      formData.append("uploadVideo", uploadVideo);
    }
    formData.append("description", description);
    formData.append("tags", tags);
    formData.append("poster", address);
    formData.append("action", "edit_video_post");

    try {
      const response = await axios.post(apiURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      });

      if (response.data.success) {
        getVideo();

        showAlert({
          title: "Successful!",
          text: response.data.message,
          icon: "success",
          confirmButtonText: "Sounds good",
        });
      } else {
        console.log("Error:", response);
        showAlert({
          title: "Error!",
          text: response.data.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      showAlert({
        title: "Error!",
        text: "An error occurred while updating the video. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <div>
      <div id="wrapper-container">
        <Header />
        <div id="main-content" style={{ background: "#1e1e1e" }}>
          <div className="content-area">
            <div className="page-title">
              <div
                className="main-top"
                style={{
                  backgroundImage: "url(assets/images/bg-page-title.jpg)",
                }}
              >
                <div className="overlay-top-header" />
                <div
                  className="content container"
                  style={{ paddingBottom: "30px" }}
                >
                  <h1>EDIT VIDEO DETAILS</h1>
                  <div className="description">
                    Use the form below to edit your videos. You get paid
                    everytime others watch your videos.
                  </div>
                </div>
              </div>
            </div>
            <div className="site-content layout-1">
              <div className="container">
                <div className="form-container">
                  {!isConnected || video.poster !== address ? (
                    <div className="alert-warning p-3 my-3 rounded">
                      <i
                        className="fa fa-exclamation-triangle"
                        aria-hidden="true"
                      ></i>{" "}
                      Please connect your wallet to be able to edit this video.
                    </div>
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="videoCategory">
                              Video Category
                            </label>
                            <select
                              onChange={(e) => setCategory(e.target.value)}
                              required
                            >
                              <option>Select a category</option>
                              {categories.length > 0 &&
                                categories.map((cat, i) => (
                                  <option
                                    key={`cat${i}`}
                                    value={cat.category}
                                    selected={cat.category == video.category}
                                  >
                                    {cat.category}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="videoTitle">Video Title</label>
                            <input
                              type="text"
                              className="form-control"
                              id="videoTitle"
                              placeholder="Enter video title"
                              onChange={(e) => setTitle(e.target.value)}
                              value={title}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="isVR"
                                id="isVRYes"
                                value="yes"
                                onChange={handleRadioChange}
                                checked={isVR === "yes"}
                                required
                              />
                              <label
                                className="form-check-label"
                                htmlFor="isVRYes"
                              >
                                This is a VR compatible video
                              </label>
                            </div>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="isVR"
                                id="isVRNo"
                                value="no"
                                onChange={handleRadioChange}
                                checked={isVR === "no"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="isVRNo"
                              >
                                This is a normal video
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="isAI"
                                id="isAIYes"
                                value="yes"
                                onChange={(e) => setIsAI(e.target.value)}
                                required
                                checked={isAI === "yes"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="isAIYes"
                              >
                                This video was made with AI
                              </label>
                            </div>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="isAI"
                                id="isAINo"
                                value="no"
                                onChange={(e) => setIsAI(e.target.value)}
                                checked={isAI === "no"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="isAINo"
                              >
                                This is me in the video
                              </label>
                            </div>
                          </div>
                        </div>
                        {user.is_creator === "yes" && (
                          <div className="col-md-6">
                            <div className="form-group">
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="isPR"
                                  id="isPRYes"
                                  value={1}
                                  onChange={handlePrivacyChange}
                                  checked={isPrivate == 1}
                                  required
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="isPRYes"
                                >
                                  Only my subscribers can watch this video
                                </label>
                              </div>
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  name="isPR"
                                  type="radio"
                                  id="isPRNo"
                                  value={0}
                                  onChange={handlePrivacyChange}
                                  checked={isPrivate == 0}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="isPRNo"
                                >
                                  Everyone can watch this video
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="videoDescription">
                              Video Description
                            </label>
                            <textarea
                              className="form-control"
                              id="videoDescription"
                              style={{ height: "120px" }}
                              placeholder="Enter video description"
                              value={description}
                              required
                              onChange={(e) => setDescription(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 text-center">
                          <div className="form-group">
                            <label htmlFor="coverPhoto">
                              <img
                                src={coverPhotoPreview || video.cover_photo}
                                width={200}
                                alt="cover placeholder"
                              />
                            </label>
                            <div className="custom-file">
                              <input
                                type="file"
                                className="custom-file-input"
                                id="coverPhoto"
                                accept="image/*"
                                onChange={handleCoverPhotoChange}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="coverPhoto"
                              >
                                Choose cover photo
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 text-center">
                          <div className="form-group">
                            <label htmlFor="uploadVideo">
                              <video
                                src={uploadVideoPreview || video.video}
                                width={200}
                                controls
                                alt="video placeholder"
                              />
                            </label>
                            <div className="custom-file">
                              <input
                                type="file"
                                className="custom-file-input"
                                id="uploadVideo"
                                accept="video/mp4"
                                onChange={handleVideoChange}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="uploadVideo"
                              >
                                Choose video file
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="videoTags">Tags</label>
                            <input
                              type="text"
                              className="form-control"
                              id="videoTags"
                              placeholder="Enter tags separated by commas"
                              value={tags}
                              onChange={(e) => setTags(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        {uploadProgress > 0 && (
                          <div className="col-md-12 my-4">
                            <div className="progress">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: `${uploadProgress}%` }}
                                aria-valuenow={uploadProgress}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                {uploadProgress}%
                              </div>
                            </div>
                          </div>
                        )}
                        <div
                          style={{ display: "none" }}
                          id="alert-success"
                          className="col-md-12 alert-success p-3 my-4 rounded"
                        ></div>
                        <div
                          style={{ display: "none" }}
                          id="alert-error"
                          className="col-md-12 alert-danger p-3 my-4 rounded"
                        ></div>
                        <div className="col-md-12">
                          <button
                            type="submit"
                            className="btn btn-custom btn-block"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
      <div id="back-to-top" className="btn-back-to-top">
        <i className="ion ion-ios-arrow-thin-up" />
      </div>
    </div>
  );
}

export default EditVideo;
