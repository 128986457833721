import React, { useState, useContext, useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Context } from "../../context/contex";
import {
  formatNumber,
  formatLocalTime,
  shortenAddress,
  getQueryParam,
  showAlert,
} from "../../utils/helpers";
import axios from "axios";
import $ from "jquery";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper/modules";
import "./services.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";

const styles = [
  "enhance",
  "cinematic-diva",
  "nude",
  "nsfw",
  "sex",
  "abstract-expressionism",
  "academia",
  "action-figure",
  "adorable-3d-character",
  "adorable-kawaii",
  "art-deco",
  "art-nouveau",
  "astral-aura",
  "avant-garde",
  "baroque",
  "bauhaus-style-poster",
  "blueprint-schematic-drawing",
  "caricature",
  "cel-shaded-art",
  "character-design-sheet",
  "classicism-art",
  "color-field-painting",
  "colored-pencil-art",
  "conceptual-art",
  "constructivism",
  "cubism",
  "dadaism",
  "dark-fantasy",
  "dark-moody-atmosphere",
  "dmt-art",
  "doodle-art",
  "double-exposure",
  "dripping-paint-splatter",
  "expressionism",
  "faded-polaroid-photo",
  "fauvism",
  "flat-2d",
  "fortnite-style",
  "futurism",
  "glitchcore",
  "glo-fi",
  "googie-style",
  "graffiti-art",
  "harlem-renaissance-art",
  "high-fashion",
  "idyllic",
  "impressionism",
  "infographic-drawing",
  "ink-dripping-drawing",
  "japanese-ink-drawing",
  "knolling-photography",
  "light-cheery-atmosphere",
  "logo-design",
  "luxurious-elegance",
  "macro-photography",
  "mandola-art",
  "marker-drawing",
  "medievalism",
  "minimalism",
  "neo-baroque",
  "neo-byzantine",
  "neo-futurism",
  "neo-impressionism",
  "neo-rococo",
  "neoclassicism",
  "op-art",
  "ornate-and-intricate",
  "pencil-sketch-drawing",
  "pop-art-2",
  "rococo",
  "silhouette-art",
  "simple-vector-art",
  "sketchup",
  "steampunk-2",
  "surrealism",
  "suprematism",
  "terragen",
  "tranquil-relaxing-atmosphere",
  "sticker-designs",
  "vibrant-rim-light",
  "volumetric-lighting",
  "watercolor",
  "whimsical-and-playful",
  "sharp",
  "masterpiece",
  "photograph",
  "negative",
  "cinematic",
  "ads-advertising",
  "ads-automotive",
  "ads-corporate",
  "ads-fashion-editorial",
  "ads-food-photography",
  "ads-gourmet-food-photography",
  "ads-luxury",
  "ads-real-estate",
  "ads-retail",
  "abstract",
  "abstract-expressionism",
  "art-deco",
  "art-nouveau",
  "constructivist",
  "cubist",
  "expressionist",
  "graffiti",
  "hyperrealism",
  "impressionist",
  "pointillism",
  "pop-art",
  "psychedelic",
  "renaissance",
  "steampunk",
  "surrealist",
  "typography",
  "watercolor",
  "futuristic-biomechanical",
  "futuristic-biomechanical-cyberpunk",
  "futuristic-cybernetic",
  "futuristic-cybernetic-robot",
  "futuristic-cyberpunk-cityscape",
  "futuristic-futuristic",
  "futuristic-retro-cyberpunk",
  "futuristic-retro",
  "futuristic-sci-fi",
  "futuristic-vaporwave",
  "game-bubble",
  "game-cyberpunk",
  "game-fighting",
  "game-gta",
  "game-mario",
  "game-minecraft",
  "game-pokemon",
  "game-retro-arcade",
  "game-retro",
  "game-rpg-fantasy",
  "game-strategy",
  "game-streetfighter",
  "game-zelda",
  "misc-architectural",
  "misc-disco",
  "misc-dreamscape",
  "misc-dystopian",
  "misc-fairy-tale",
  "misc-gothic",
  "misc-grunge",
  "misc-horror",
  "misc-kawaii",
  "misc-lovecraftian",
  "misc-macabre",
  "misc-manga",
  "misc-metropolis",
  "misc-minimalist",
  "misc-monochrome",
  "misc-nautical",
  "misc-space",
  "misc-stained-glass",
  "misc-techwear-fashion",
  "misc-tribal",
  "misc-zentangle",
  "papercraft-collage",
  "papercraft-flat-papercut",
  "papercraft-kirigami",
  "papercraft-paper-mache",
  "papercraft-paper-quilling",
  "papercraft-papercut-collage",
  "papercraft-papercut-shadow-box",
  "papercraft-stacked-papercut",
  "papercraft-thick-layered-papercut",
  "photo-alien",
  "photo-film-noir",
  "photo-glamour",
  "photo-hdr",
  "photo-iphone-photographic",
  "photo-long-exposure",
  "photo-neon-noir",
  "photo-silhouette",
  "photo-tilt-shift",
  "3d-model",
  "analog-film",
  "anime",
  "cinematic",
  "comic-book",
  "craft-clay",
  "digital-art",
  "fantasy-art",
  "isometric",
  "line-art",
  "lowpoly",
  "neonpunk",
  "origami",
  "photographic",
  "pixel-art",
  "texture",
];

function PornFace() {
  const [topCreators, setTopCreators] = useState([]);
  const [prompt, setPrompt] = useState("");
  const [nPrompt, setNPrompt] = useState("");
  const [width, setWidth] = useState(512);
  const [height, setHeight] = useState(640);
  const [quantity, setQuantity] = useState(1);

  const [model, setModel] = useState("urpm-v13");
  const [isHD, setIsHD] = useState(false);
  const [imgStyle, setImgStyle] = useState("nude");
  const [data, setData] = useState({});
  const [dbImages, setDbImages] = useState([]);
  const [dbImage, setDbImage] = useState({});

  const {
    address,
    token,
    chainId,
    isConnected,
    walletProvider,
    tokenBalance,
    apiURL,
    loading,
    setLoading,
  } = useContext(Context);

  const handleToggle = () => {
    setIsHD(!isHD);
  };

  const getUserImages = async (wallet) => {
    // if (!wallet || wallet == null) return;
    try {
      const details = {
        action: "get_generated_images",
        wallet: wallet,
      };

      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(details),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        refresh();
        console.error("Errors:", data.error);
      } else {
        setDbImages(data);
        //  console.log("IMAGES:", data);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    if (!isConnected) return;
    getUserImages(address);
  }, [address, isConnected]);

  const refresh = () => {
    setData({});
    setPrompt("");
    setNPrompt("");
    setIsHD(false);
    setDbImage({});
    // setDbImages({});
    // getUserImages(address);
  };

  const formatStyleName = (style) => {
    return style
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const generateImage = async () => {
    if (!isConnected) {
      showAlert({
        title: "Error!",
        text: "Please connect a wallet first.",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }
    if (!prompt || !width || !height || !model) {
      showAlert({
        title: "Error!",
        text: "One or more required fields are missing.",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }
    if (+height > 1024) {
      showAlert({
        title: "Error!",
        text: "Height cannot be more tham 1024px.",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }
    if (+width > 1024) {
      showAlert({
        title: "Error!",
        text: "Width cannot be more tham 1024px.",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }

    const raw = JSON.stringify({
      key: "OH0sQekG8AYCG7HqtLmYTF8C8Pv57Yx34QPdVA2Z8plTjHw9cWMqzxscxS0H",
      model_id: model,
      prompt: prompt,
      negative_prompt: nPrompt,
      width: width,
      height: height,
      samples: quantity,
      num_inference_steps: "30",
      safety_checker: "no",
      enhance_prompt: "yes",
      seed: null,
      guidance_scale: 7.5,
      multi_lingual: "no",
      panorama: isHD ? "yes" : "no",
      self_attention: "no",
      upscale: "no",
      embeddings_model: null,
      lora_model: null,
      tomesd: "yes",
      use_karras_sigmas: "yes",
      vae: null,
      lora_strength: null,
      scheduler: "UniPCMultistepScheduler",
      webhook: null,
      track_id: null,
    });
    setLoading(true);
    setDbImage({});
    try {
      const response = await fetch(
        "https://modelslab.com/api/v6/images/text2img",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: raw,
          redirect: "follow",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("GEN", data);
      if (data.status === "processing" && data.fetch_result) {
        pollForResult(data.fetch_result);
      } else if (data.status === "success") {
        setData(data);
        setLoading(false);
      } else if (data.status === "error") {
        console.log("GENERATE ERROR", data);

        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error.message);
      setLoading(false);
    }
  };

  const pollForResult = async (url) => {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key: "OH0sQekG8AYCG7HqtLmYTF8C8Pv57Yx34QPdVA2Z8plTjHw9cWMqzxscxS0H",
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("POLL RESULT", data);
      if (data.status === "processing") {
        setTimeout(() => pollForResult(url), 3000);
      } else if (data.status === "success") {
        setData(data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error.message);
      setLoading(false);
    }
  };

  async function saveImages() {
    if (!isConnected) {
      showAlert({
        title: "Error!",
        text: "Please connect a wallet first.",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }
    if (Object.keys(data).length === 0 || data.output.length === 0) {
      showAlert({
        title: "Error!",
        text: "No images found.",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }
    setLoading(true);

    try {
      const formData = new FormData();

      // Append JSON details
      formData.append("id", data.id);
      if (data?.meta?.prompt != undefined) {
        formData.append("prompt", data.meta.prompt);
        formData.append("model_id", data.meta.model_id);
        formData.append("negative_prompt", data.meta.negative_prompt);
        formData.append("n_samples", data.meta.n_samples);
      }

      formData.append("wallet", address);
      formData.append("action", "save_generated_images");

      // Download and append images
      for (let i = 0; i < data.output.length; i++) {
        const imageBlob = await fetch(data.output[i]).then((res) => res.blob());
        formData.append(`image_${i}`, imageBlob, `image_${i}.png`);
      }

      // Send FormData in one API call
      const response = await fetch(apiURL, {
        method: "POST",
        body: formData,
      });

      const datas = await response.json();
      if (datas.success) {
        getUserImages(address);
        showAlert({
          title: "Success!",
          text: datas.message,
          icon: "success",
          confirmButtonText: "Sounds good",
        });
        setLoading(false);
      } else {
        showAlert({
          title: "Error!",
          text: datas.message,
          icon: "error",
          confirmButtonText: "Ok",
        });
        console.log("Error:", datas.message);

        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  }

  useEffect(() => {
    // jQuery for toggling the sidebar
    $("#toggleSidebar").on("click", function () {
      $("#sidebar").toggleClass("show");
    });

    $("#closeSidebar").on("click", function () {
      $("#sidebar").toggleClass("show");
    });

    // Cleanup event listeners on component unmount
    return () => {
      $("#toggleSidebar").off("click");
      $("#closeSidebar").off("click");
    };
  }, []);

  return (
    <div>
      <div id="wrapper-container">
        <Header />
        <div id="main-content" style={{ background: "#1e1e1e" }}>
          <div className="content-area">
            <div className="page-title">
              <div
                className="main-top"
                style={{
                  backgroundImage: "url(assets/images/nft-bg4.jpeg)",
                  backgroundAttachment: "fixed",
                }}
              >
                <div className="overlay-top-header" />
                <div
                  className="content container"
                  style={{ paddingBottom: "30px" }}
                >
                  <h1 style={{ fontWeight: 700 }}>YOUR PORN FACE</h1>
                  <div className="description" style={{ color: "#fff" }}>
                    Now it's easy to create your Ai fantasy porn images. Just
                    type in a prompt, describing what you want in the field
                    below and our Ai model will create it.
                  </div>
                </div>
              </div>
            </div>
            {isConnected && (
              <div className="site-content layout-1">
                <div className="container-fluid">
                  <div className="d-flex">
                    <div className="sidebar" id="sidebar">
                      <div className="text-right">
                        <button
                          className="btn btn-danger d-md-none mb-3"
                          id="closeSidebar"
                        >
                          <i
                            style={{ fontSize: "30px" }}
                            className="fa fa-times-circle"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                      <div className="list-group list-group-flush">
                        <h3 className="p-4 text-capitalize">
                          Your previously generated images
                        </h3>
                        {Object.keys(dbImages).length > 0 ? (
                          dbImages.map((d, i) => {
                            const imagePaths = JSON.parse(d.image_paths); // Parse the image_paths string
                            return (
                              <button
                                key={i}
                                className="list-group-item list-group-item-action"
                                onClick={() => setDbImage(d)}
                              >
                                <img src={imagePaths[0]} alt="Avatar" />
                                <span>
                                  Generated on: {formatLocalTime(d.add_date)}
                                  {" | "}
                                  {d.samples} images
                                </span>
                              </button>
                            );
                          })
                        ) : (
                          <div className="text-center">
                            <img
                              src="assets/images/fly.gif"
                              height={100}
                              alt="No images"
                            />
                            <p>No Ai images here yet...</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="main-content flex-grow-1">
                      <button
                        className="btn btn-danger d-md-none mb-3"
                        id="toggleSidebar"
                      >
                        Show previous
                      </button>
                      <div className="container">
                        <div className="row">
                          <div className="col-12">
                            <div className="card bg-dark">
                              {Object.keys(data).length === 0 &&
                                Object.keys(dbImage).length === 0 && (
                                  <img
                                    src="assets/images/porn-prev.png"
                                    className="card-img-top"
                                    alt="Generated Image"
                                    id="imagePreview"
                                    height={300}
                                  />
                                )}
                              {Object.keys(dbImage).length === 0 &&
                                Object.keys(data).length > 0 && (
                                  <div className="row">
                                    {data.output.map((g, i) => (
                                      <div key={i} className="col-sm-6 my-2">
                                        <img
                                          src={g}
                                          className="card-img-top"
                                          alt="Generated Image"
                                          id={`imagePreview-${i}`}
                                          style={{
                                            width: "100%",
                                            height: "auto",
                                          }}
                                        />
                                      </div>
                                    ))}
                                  </div>
                                )}
                              {Object.keys(dbImage).length > 0 && (
                                <div className="row">
                                  {JSON.parse(dbImage.image_paths).map(
                                    (imagePath, index) => (
                                      <div
                                        className="col-sm-6 my-2"
                                        key={index}
                                      >
                                        <img
                                          src={imagePath}
                                          className="card-img-top"
                                          alt="Generated Image"
                                          style={{
                                            width: "100%",
                                            height: "auto",
                                          }}
                                        />
                                      </div>
                                    )
                                  )}
                                </div>
                              )}

                              <div className="card-body">
                                <div className="text-right d-flex mb-5">
                                  {Object.keys(data).length > 0 && (
                                    <div className="mr-3">
                                      <button
                                        className="btn btn-success mb-3"
                                        id="downloadButton"
                                        onClick={saveImages}
                                      >
                                        Save Images
                                      </button>
                                    </div>
                                  )}

                                  <div>
                                    <button
                                      className="btn btn-danger mb-3"
                                      id="downloadButton"
                                      onClick={refresh}
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                                <label>Prompt{isHD}</label>
                                <textarea
                                  style={{ background: "#000" }}
                                  className="form-control"
                                  rows={6}
                                  placeholder="Type a detailed description of the image you need."
                                  id="promptText"
                                  value={prompt}
                                  onInput={(e) => setPrompt(e.target.value)}
                                  required
                                />

                                <label>Negative prompt (optional)</label>
                                <textarea
                                  style={{ background: "#000" }}
                                  className="form-control"
                                  rows={6}
                                  placeholder="Type everything you don't want in the image."
                                  value={nPrompt}
                                  onInput={(e) => setNPrompt(e.target.value)}
                                />

                                <div className="row my-3">
                                  <div className="col-md-4">
                                    <label>Width (1024 max)</label>
                                    <input
                                      type="number"
                                      style={{ background: "#000" }}
                                      className="form-control"
                                      placeholder="Image width"
                                      value={width}
                                      onInput={(e) => setWidth(e.target.value)}
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <label>Height (1024 max)</label>
                                    <input
                                      type="number"
                                      style={{ background: "#000" }}
                                      className="form-control"
                                      placeholder="Image width"
                                      value={height}
                                      onInput={(e) => setHeight(e.target.value)}
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <label>Ai Model</label>
                                    <select
                                      onChange={(e) => setModel(e.target.value)}
                                    >
                                      <option disabled>Chose a model</option>
                                      <option value="urpm-v13" selected>
                                        Uber Realistic Porn
                                      </option>
                                      <option value="realistic-vision-v13">
                                        Realistic Vision V1.3
                                      </option>
                                      <option value="chillout-mix-appfact">
                                        Chillout mix
                                      </option>
                                      <option value="deliberateappfactory">
                                        Deliberate
                                      </option>
                                      <option value="bigger-girls-models">
                                        Bigger girls
                                      </option>
                                      <option value="epicrealism-v3-updat">
                                        epicRealism
                                      </option>
                                    </select>
                                  </div>
                                </div>

                                <div className=" my-3 row">
                                  <div className="col-md-4">
                                    <div>
                                      <label htmlFor="isHD">
                                        Panorama image
                                      </label>
                                    </div>
                                    <label className="switch mr-3">
                                      <input
                                        id="isHD"
                                        type="checkbox"
                                        checked={isHD}
                                        onChange={handleToggle}
                                        value={isHD ? "yes" : "no"}
                                      />
                                      <span className="slider round" />
                                    </label>
                                  </div>

                                  <div className="col-md-4">
                                    <label>Image style</label>
                                    <select
                                      onChange={(e) =>
                                        setImgStyle(e.target.value)
                                      }
                                    >
                                      {styles.map((style, i) => (
                                        <option
                                          key={"s_" + i}
                                          value={style}
                                          selected={style === "nude"}
                                        >
                                          {formatStyleName(style)}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="col-md-4">
                                    <label>Quantity</label>
                                    <select
                                      onChange={(e) =>
                                        setQuantity(e.target.value)
                                      }
                                    >
                                      <option disabled>How many images?</option>
                                      <option value="1" selected>
                                        1
                                      </option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="text-center">
                                  <button
                                    className="btn btn-danger mt-3"
                                    id="generateButton"
                                    onClick={generateImage}
                                  >
                                    Generate Image
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!isConnected && (
              <div className="alert-warning p-3 my-3 rounded">
                <i className="fa fa-info-circle" aria-hidden="true"></i> Please
                connect your wallet to use this app.
              </div>
            )}
          </div>
        </div>

        <Footer />
      </div>
      <div id="back-to-top" className="btn-back-to-top">
        <i className="ion ion-ios-arrow-thin-up" />
      </div>
    </div>
  );
}

export default PornFace;
