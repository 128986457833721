import React from "react";
import { Canvas, useLoader } from "@react-three/fiber";
import { OrbitControls, useTexture } from "@react-three/drei";
import { Box, MeshBasicMaterial } from "@react-three/drei";
import { TextureLoader } from "three";

import { useState } from "react";
import ClickableFace from "./clickableFace";

const Panorama = ({ imageUrls }) => {
  const textures = useLoader(
    TextureLoader,
    imageUrls.map((image) => image.url)
  );

  const facePositions = [
    { position: [0, 0, 5], rotation: [0, 0, 0] }, // Front
    { position: [5, 0, 0], rotation: [0, -Math.PI / 2, 0] }, // Right
    { position: [0, 0, -5], rotation: [0, Math.PI, 0] }, // Back
    { position: [-5, 0, 0], rotation: [0, Math.PI / 2, 0] }, // Left
    { position: [0, 5, 0], rotation: [Math.PI / 2, 0, 0] }, // Top
    { position: [0, -5, 0], rotation: [-Math.PI / 2, 0, 0] }, // Bottom
  ];

  return (
    <group>
      {facePositions.map((face, index) => {
        const textureIndex = index % textures.length;
        return (
          <ClickableFace
            key={index}
            texture={textures[textureIndex]}
            link={imageUrls[textureIndex].link}
            position={face.position}
            rotation={face.rotation}
          />
        );
      })}
    </group>
  );
};

export default Panorama;
