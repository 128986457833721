import React from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls, useTexture } from "@react-three/drei";
import { Box, MeshBasicMaterial } from "@react-three/drei";
import { useState } from "react";

const ClickableFace = ({ texture, link }) => {
  const handleClick = () => {
    window.open(link, "_blank");
  };

  return (
    <mesh onClick={handleClick}>
      <planeGeometry args={[10, 10]} />
      <meshBasicMaterial map={texture} transparent={true} opacity={0.99} />
    </mesh>
  );
};

export default ClickableFace;
