import { ethers, Contract, JsonRpcProvider } from "ethers";
import abi from "./controller.json";
import { Context } from "../context/contex";

export async function ReadContract() {
  const controlContract = "0x8A6fB108B549B1e49C67c53d26Eb5F31dEd2dFf2";

  const provider = new JsonRpcProvider(
    "https://data-seed-prebsc-1-s1.binance.org:8545"
  );

  //const provider = new JsonRpcProvider("https://bsc-dataseed.binance.org/");

  const contract = new Contract(controlContract, abi, provider);
  // console.log("CONTRACTA", contract);
  return contract;
}
