// src/index.js or src/main.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import WatchHome from "./pages/watch/index";
import WatchVR from "./pages/watch/index-vr";
import Profile from "./pages/Profile/profile";
import EditVideo from "./pages/watch/edit-post";
import EditProfile from "./pages/Profile/edit-profile";

import PostVideo from "./pages/watch/post-video";
import SingleVideo from "./pages/watch/single-video";
import FanReal from "./pages/fan/fan-real";
import Creators from "./pages/fan/creators";
import BecomeCreator from "./pages/fan/become-creator";
import PornFace from "./pages/services/porn-face";
import FaceSwap from "./pages/services/face-swap";

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<WatchHome />} />
      <Route path="watch" element={<WatchHome />} />
      <Route path="watch-vr" element={<WatchVR />} />
      <Route path="profile" element={<Profile />} />
      <Route path="edit-post" element={<EditVideo />} />
      <Route path="edit-profile" element={<EditProfile />} />

      <Route path="post-video" element={<PostVideo />} />
      <Route path="single-video" element={<SingleVideo />} />
      <Route path="fan-real" element={<FanReal />} />
      <Route path="creators" element={<Creators />} />
      <Route path="become-creator" element={<BecomeCreator />} />
      <Route path="porn-face" element={<PornFace />} />
      <Route path="face-swap" element={<FaceSwap />} />
    </Routes>
  </Router>
);

export default App;
