import React, { useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const showAlert = ({ title, text, icon, confirmButtonText }) => {
  return MySwal.fire({
    title,
    text,
    icon,
    confirmButtonText,
  });
};

export function getPercentage(number, percent) {
  return (number * percent) / 100;
}

export const formatNumber = (number) => {
  return Number(number).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const formatLocalTime = (timestamp) => {
  const date = new Date(timestamp * 1000); // convert timestamp to milliseconds
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    // timeZoneName: "short",
  };
  return date.toLocaleString(undefined, options);
};

export const shortenAddress = (address) => {
  if (!address) return "";
  return `${address.slice(0, 4)}...${address.slice(-4)}`;
};

export const getQueryParam = (param) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

export const searchArray = (likedByUsers, wallet) => {
  if (!likedByUsers) return false;
  const user = likedByUsers.find((user) => user.wallet === wallet);
  return !!user;
};

export const getPaginatedData = (data, currentPage, itemsPerPage) => {
  const start = currentPage * itemsPerPage;
  const end = start + itemsPerPage;
  return data.slice(start, end);
};

export const generateNonce = () => {
  return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
};
