import React, { useState, useContext, useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Context } from "../../context/contex";
import {
  formatNumber,
  formatLocalTime,
  shortenAddress,
  getQueryParam,
  showAlert,
} from "../../utils/helpers";
import axios from "axios";
import $ from "jquery";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper/modules";
import "./services.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";

function FaceSwap() {
  const [prompt, setPrompt] = useState("");
  const [nPrompt, setNPrompt] = useState("");

  const [isHD, setIsHD] = useState(false);
  const [data, setData] = useState({});
  const [dbImages, setDbImages] = useState([]);
  const [dbImage, setDbImage] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [swapPhotoPreview, setSwapPhotoPreview] = useState(null);
  const [swapPhoto, setSwapPhoto] = useState(null);
  const [swapVideo, setSwapVideo] = useState(null);
  const [swapVideoPreview, setSwapVideoPreview] = useState(null);

  const [swapPhotoPath, setSwapPhotoPath] = useState(null);
  const [swapVideoPath, setSwapVideoPath] = useState(null);

  const {
    address,
    token,
    chainId,
    isConnected,
    walletProvider,
    tokenBalance,
    apiURL,
    loading,
    setLoading,
  } = useContext(Context);

  const mergeImagePaths = (data) => {
    let mergedPaths = [];
    data.forEach((record) => {
      const paths = JSON.parse(record.image_paths);
      mergedPaths = mergedPaths.concat(paths);
    });
    //  console.log("PATH", mergedPaths);
    return mergedPaths;
  };

  const handleImageClick = (image, index) => {
    setSelectedImage(index); // Set the selected image index
    setDbImage(image);
  };

  const handleSwapPhoto = async (e) => {
    const file = e.target.files[0];
    if (file) {
      await handleSwapUpload(file, false);
      setSwapPhoto(file);
      setSwapPhotoPreview(URL.createObjectURL(file));
      //   await handleSwapUpload(file);
    }
  };

  const handleVideoChange = async (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("video/")) {
      const videoElement = document.createElement("video");
      videoElement.preload = "metadata";
      videoElement.onloadedmetadata = async function () {
        window.URL.revokeObjectURL(videoElement.src);
        const duration = videoElement.duration;
        if (duration > 59) {
          setSwapVideoPreview(null);
          return showAlert({
            title: "Error!",
            text: "Video must be less than 1 minute.",
            icon: "error",
            confirmButtonText: "Ok",
          });
        } else {
          await handleSwapUpload(file, true);
          setSwapVideoPreview(URL.createObjectURL(file));
        }
      };
      videoElement.src = URL.createObjectURL(file);
    }
  };

  const getUserImages = async (wallet) => {
    // if (!wallet || wallet == null) return;
    try {
      const details = {
        action: "get_generated_images",
        wallet: wallet,
      };

      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(details),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        refresh();
        console.error("Errors:", data.error);
      } else {
        const m = mergeImagePaths(data);
        setDbImages(m);
        //  console.log("IMAGES:", m);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    if (!isConnected) return;
    getUserImages(address);
  }, [address, isConnected]);

  const refresh = () => {
    setData({});
    setDbImage("");
    setDbImages([]);
    // getUserImages(address);
  };

  const handleSwapUpload = async (swapPhoto, isVideo) => {
    if (!swapPhoto) {
      showAlert({
        title: "Error!",
        text: "Please select an image to upload",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }

    const formData = new FormData();
    formData.append("image", swapPhoto);
    formData.append("action", "upload_swap_face");

    try {
      const response = await axios.post(apiURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.data.path) {
        if (!isVideo) {
          setSwapPhotoPath(response.data.path);
        } else {
          setSwapVideoPath(response.data.path);
        }
      } else {
        showAlert({
          title: "Error!",
          text: response.data.error,
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    } catch (error) {
      console.error("Error uploading the file:", error);
      alert("Error uploading the file.");
    }
  };

  const swapFace = async () => {
    if (!isConnected) {
      showAlert({
        title: "Error!",
        text: "Please connect a wallet first.",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }
    if (!swapPhotoPath || !dbImage) {
      showAlert({
        title: "Error!",
        text: "Original or target image is missing. Make sure to select these photos first.",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }

    const raw = JSON.stringify({
      key: "OH0sQekG8AYCG7HqtLmYTF8C8Pv57Yx34QPdVA2Z8plTjHw9cWMqzxscxS0H",
      target_image:
        "https://scontent.fabv3-1.fna.fbcdn.net/v/t39.30808-1/445762692_2820859624737274_7706838341452592144_n.jpg?stp=dst-jpg_s480x480&_nc_cat=105&ccb=1-7&_nc_sid=0ecb9b&_nc_eui2=AeFSqxbOHazKhIEHSBy7SeiHy53tG-mHfgPLne0b6Yd-AwW0kSmVO7mtIPKzJWOWwH9pW8tAFvpTQOt_9qelNwRm&_nc_ohc=uxqbjGZ8aGIQ7kNvgHosyQW&_nc_ht=scontent.fabv3-1.fna&oh=00_AYBbhzp2ixnzccRU5Mp687dKoct9j6bVV8PbuLLxLJ6ObA&oe=66923ACE",
      init_image:
        "https://media.premiumtimesng.com/wp-content/files/2016/07/Daniel-Dino-Melaye.jpg",
      watermark: false,
      webhook: null,
      track_id: null,
    });
    setLoading(true);
    setData({});
    try {
      const response = await fetch(
        "https://modelslab.com/api/v6/deepfake/multiple_face_swap",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: raw,
          redirect: "follow",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("SWAP", data);
      if (data.status === "processing" && data.fetch_result) {
        pollForResult(data.fetch_result);
      } else if (data.status === "success") {
        setData(data);
        setLoading(false);
      } else if (data.status === "error") {
        console.log("SWAP ERROR", data);

        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error.message);
      setLoading(false);
    }
  };

  const swapVideoFace = async () => {
    if (!isConnected) {
      showAlert({
        title: "Error!",
        text: "Please connect a wallet first.",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }
    if (!swapVideoPath || !dbImage) {
      showAlert({
        title: "Error!",
        text: "Original or target video or  image is missing. Make sure to select these first.",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }

    const raw = JSON.stringify({
      key: "OH0sQekG8AYCG7HqtLmYTF8C8Pv57Yx34QPdVA2Z8plTjHw9cWMqzxscxS0H",
      init_video: "https://ai18pluspresale.com/video.mp4",
      init_image:
        "https://media.premiumtimesng.com/wp-content/files/2016/07/Daniel-Dino-Melaye.jpg",
      watermark: false,
      webhook: null,
      track_id: null,
    });
    setLoading(true);
    setData({});
    try {
      const response = await fetch(
        "https://modelslab.com/api/v6/deepfake/single_video_swap",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: raw,
          redirect: "follow",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("SWAP", data);
      if (data.status === "processing" && data.fetch_result) {
        pollForResult(data.fetch_result);
      } else if (data.status === "success") {
        setData(data);
        setLoading(false);
      } else if (data.status === "error") {
        console.log("SWAP ERROR", data);

        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error.message);
      setLoading(false);
    }
  };

  const pollForResult = async (url) => {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key: "OH0sQekG8AYCG7HqtLmYTF8C8Pv57Yx34QPdVA2Z8plTjHw9cWMqzxscxS0H",
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("POLL RESULT", data);
      if (data.status === "processing") {
        setTimeout(() => pollForResult(url), 3000);
      } else if (data.status === "success") {
        setData(data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error.message);
      setLoading(false);
    }
  };

  return (
    <div>
      <div id="wrapper-container">
        <Header />
        <div id="main-content" style={{ background: "#1e1e1e" }}>
          <div className="content-area">
            <div className="page-title">
              <div
                className="main-top"
                style={{
                  backgroundImage: "url(assets/images/nft-bg4.jpeg)",
                  backgroundAttachment: "fixed",
                }}
              >
                <div className="overlay-top-header" />
                <div
                  className="content container"
                  style={{ paddingBottom: "30px" }}
                >
                  <h1 style={{ fontWeight: 700 }}>LET'S FACE-SWAP</h1>
                  <div className="description" style={{ color: "#fff" }}>
                    <b>Tips:</b>
                    <br />
                    To get best on your Ai face swap, please ensure the quality
                    of the video you want to reface has a high resolution with
                    clear faces.
                  </div>
                </div>
              </div>
            </div>
            <div className="site-content layout-1">
              <div className="container">
                {/* Nav tabs */}
                <ul className="nav nav-tabs mt-4" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#images"
                    >
                      Images
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#videos">
                      Videos
                    </a>
                  </li>
                </ul>
                {/* Tab content */}
                <div className="tab-content">
                  <div id="images" className="container tab-pane active">
                    <br />
                    <h2>HOW IT WORKS:</h2>
                    <ul className="stylish-list">
                      <li>
                        Upload the image containing the face(s) you want to
                        replace or paste the link to the image in the field
                        provided below.
                      </li>
                      <li>
                        Pick a replacement face from your Ai models or create a
                        new one. The number of faces in both images must be
                        equal and faces should be frontal and very clear for
                        best results.
                      </li>
                      <li>
                        Press the START SWAP button and wait for the magic.
                      </li>
                    </ul>
                    <div className="form-container">
                      <>
                        <div className="row">
                          <div className="col-md-5 text-center">
                            <div className="form-group">
                              <label htmlFor="oFace">
                                <img
                                  src={
                                    swapPhotoPreview || "assets/images/sil.png"
                                  }
                                  width={200}
                                />
                              </label>
                              <div className="custom-file">
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  id="oFace"
                                  required
                                  onChange={handleSwapPhoto}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="oFace"
                                >
                                  Choose original face
                                </label>
                                <div>
                                  <input
                                    style={{
                                      background: "#000",
                                      color: "#fff",
                                      height: "35px",
                                    }}
                                    className="w-100  my-3"
                                    placeholder="Or paste image URL here..."
                                    type="text"
                                    value={swapPhotoPath || ""}
                                    onChange={(e) =>
                                      setSwapPhotoPath(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2 d-flex align-items-center justify-content-center">
                            <i
                              className="fa fa-long-arrow-right gradient-icon"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="col-md-5 text-center">
                            <div>
                              <img
                                src={`${
                                  (dbImage?.trim() || "") !== ""
                                    ? dbImage
                                    : "assets/images/sil.png"
                                }`}
                                width={200}
                              />
                            </div>
                            <div className="custom-file">
                              <label className="custom-file-label">
                                Choose replacement face from your models below
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12 text-center">
                            <h3>
                              Choose an image from your Ai models you wish to
                              swap to or{" "}
                              <a
                                className="text-warning"
                                href="/porn-face"
                                target="_blank"
                              >
                                create a new model
                              </a>
                            </h3>
                          </div>
                          <div className="col-md-12 text-center">
                            <Swiper
                              navigation={true}
                              modules={[Pagination, Autoplay, Navigation]}
                              // slidesPerView={3}
                              loop={true}
                              breakpoints={{
                                640: {
                                  slidesPerView: 2,
                                  spaceBetween: 20,
                                },
                                768: {
                                  slidesPerView: 4,
                                  spaceBetween: 30,
                                },
                                1024: {
                                  slidesPerView: 6,
                                  spaceBetween: 30,
                                },
                              }}
                              className="my-5"
                            >
                              {dbImages.length > 0 &&
                                dbImages.map((image, index) => (
                                  <SwiperSlide key={index}>
                                    <div
                                      className={`image-container ${
                                        selectedImage === index
                                          ? "selected"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleImageClick(image, index)
                                      }
                                    >
                                      <img
                                        src={image}
                                        alt={`Slide ${index}`}
                                        className={`gallery-image ${
                                          selectedImage === index
                                            ? "selected"
                                            : ""
                                        }`}
                                      />
                                    </div>
                                  </SwiperSlide>
                                ))}
                            </Swiper>
                            {dbImages.length === 0 && (
                              <div className="text-center">
                                <img
                                  src="assets/images/fly.gif"
                                  height={100}
                                  alt="No images"
                                />
                                <p>
                                  No Ai models here yet...{" "}
                                  <a
                                    className="text-warning"
                                    href="/porn-face"
                                    target="_blank"
                                  >
                                    create a new model first
                                  </a>
                                </p>
                              </div>
                            )}
                          </div>

                          <div className="col-md-12 my-3">
                            <div className="swap-result">
                              {Object.keys(data).length > 0 ? (
                                <img src={data.output[0]} height={300} />
                              ) : (
                                <>
                                  <i
                                    className="fa fa-spinner mr-2"
                                    aria-hidden="true"
                                  />
                                  <span>
                                    Waiting for result. It may take a while.....
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <button
                              className="btn btn-custom btn-block"
                              onClick={swapFace}
                            >
                              Start the Swap
                            </button>
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                  <div id="videos" className="container tab-pane fade">
                    <br />
                    <h2>HOW IT WORKS:</h2>
                    <ul className="stylish-list">
                      <li>
                        Upload the video containing the face(s) you want to
                        replace or paste the link to the video in the field
                        provided below. Video must be less than 1 minute.
                      </li>
                      <li>
                        Pick a replacement face from your Ai models or create a
                        new one. The number of faces in both image and video
                        must be equal and faces should be frontal and very clear
                        for best results.
                      </li>
                      <li>
                        Press the START SWAP button and wait for the magic.
                      </li>
                    </ul>
                    <div className="form-container">
                      <>
                        <div className="row">
                          <div className="col-md-5 text-center">
                            <div className="form-group">
                              <label htmlFor="coverPhoto">
                                <video
                                  className="img-fluid mt-3"
                                  style={{ maxWidth: "100%", height: "300px" }}
                                  muted
                                  loop
                                  autoPlay
                                >
                                  <source
                                    src={
                                      swapVideoPreview ||
                                      "assets/images/swap-video.webm"
                                    }
                                    type="video/webm"
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              </label>
                              <div className="custom-file">
                                <input
                                  accept="video/mp4"
                                  type="file"
                                  className="custom-file-input"
                                  id="swapVideo"
                                  required
                                  onChange={handleVideoChange}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="swapVideo"
                                >
                                  Choose original photo
                                </label>
                                <div>
                                  <input
                                    style={{
                                      background: "#000",
                                      color: "#fff",
                                      height: "35px",
                                    }}
                                    className="w-100  my-3"
                                    placeholder="Or paste image URL here..."
                                    type="text"
                                    value={swapVideoPath || ""}
                                    onChange={(e) =>
                                      setSwapVideoPath(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className=" col-md-2 d-flex align-items-center justify-content-center">
                            <i
                              className="fa fa-long-arrow-right gradient-icon"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="col-md-5 text-center">
                            <div className="form-group">
                              <label>
                                <img
                                  src={dbImage || "assets/images/sil.png"}
                                  width={200}
                                />
                              </label>
                              <div className="custom-file">
                                <label className="custom-file-label">
                                  Choose replacement face from your models below
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 text-center">
                            <h3>
                              Choose an image from your Ai models you wish to
                              swap to or{" "}
                              <a
                                className="text-warning"
                                href="/porn-face"
                                target="_blank"
                              >
                                create a new model
                              </a>
                            </h3>
                          </div>
                          <div className="col-md-12 text-center">
                            <Swiper
                              navigation={true}
                              modules={[Pagination, Autoplay, Navigation]}
                              // slidesPerView={3}
                              loop={true}
                              breakpoints={{
                                640: {
                                  slidesPerView: 2,
                                  spaceBetween: 20,
                                },
                                768: {
                                  slidesPerView: 4,
                                  spaceBetween: 30,
                                },
                                1024: {
                                  slidesPerView: 6,
                                  spaceBetween: 30,
                                },
                              }}
                              className="my-5"
                            >
                              {dbImages.length > 0 &&
                                dbImages.map((image, index) => (
                                  <SwiperSlide key={index}>
                                    <div
                                      className={`image-container ${
                                        selectedImage === index
                                          ? "selected"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleImageClick(image, index)
                                      }
                                    >
                                      <img
                                        src={image}
                                        alt={`Slide ${index}`}
                                        className={`gallery-image ${
                                          selectedImage === index
                                            ? "selected"
                                            : ""
                                        }`}
                                      />
                                    </div>
                                  </SwiperSlide>
                                ))}
                            </Swiper>
                            {dbImages.length === 0 && (
                              <div className="text-center">
                                <img
                                  src="assets/images/fly.gif"
                                  height={100}
                                  alt="No images"
                                />
                                <p>
                                  No Ai models here yet...{" "}
                                  <a
                                    className="text-warning"
                                    href="/porn-face"
                                    target="_blank"
                                  >
                                    create a new model first
                                  </a>
                                </p>
                              </div>
                            )}
                          </div>

                          <div className="col-md-12 my-3">
                            <div className="swap-result">
                              {Object.keys(data).length > 0 ? (
                                <img src={data.output[0]} height={300} />
                              ) : (
                                <>
                                  <i
                                    className="fa fa-spinner mr-2"
                                    aria-hidden="true"
                                  />
                                  <span>
                                    Waiting for result. It may take a while.....
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <button
                              className="btn btn-custom btn-block"
                              onClick={swapVideoFace}
                            >
                              Start the Swap
                            </button>
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>

      <div id="back-to-top" className="btn-back-to-top">
        <i className="ion ion-ios-arrow-thin-up" />
      </div>
    </div>
  );
}

export default FaceSwap;
