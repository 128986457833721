import React, { useState, useContext, useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Context } from "../../context/contex";
import {
  formatNumber,
  formatLocalTime,
  shortenAddress,
  getQueryParam,
} from "../../utils/helpers";
import axios from "axios";
import $ from "jquery";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper/modules";
import "./fan.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import usePagination from "../../components/usePagination";

function Creators() {
  const [creators, setCreators] = useState([]);
  const [popularVideos, setPopularVideos] = useState([]);
  const [popularCategories, setPopularCategories] = useState([]);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    address,
    token,
    chainId,
    isConnected,
    walletProvider,
    tokenBalance,
    apiURL,
  } = useContext(Context);
  const itemsPerPage = 20;
  const { paginatedData, appendData, resetPagination, currentPage } =
    usePagination(itemsPerPage);

  useEffect(() => {
    if (query.length > 0) {
      const fetchData = async () => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("query", query);
        formData.append("action", "search_creators");
        try {
          const response = await axios.post(apiURL, formData, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          });
          setResults(response.data);
        } catch (error) {
          console.error("Error fetching search results", error);
        }
        setIsLoading(false);
      };

      const debounceTimeout = setTimeout(() => {
        fetchData();
      }, 300); // Adjust the debounce delay as needed

      return () => clearTimeout(debounceTimeout);
    } else {
      setResults([]);
    }
  }, [query]);

  const fetchCreators = async () => {
    const formData = new FormData();
    formData.append("action", "get_creators");
    try {
      const response = await axios.post(apiURL, formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      if (response.data.error) {
        setCreators([]);
      } else {
        setCreators(response.data);
        resetPagination(response.data);
      }
    } catch (error) {
      console.error("Error fetching search results", error);
    }
  };

  useEffect(() => {
    fetchCreators();
  }, []);

  const handleLoadMore = () => {
    appendData(creators);
  };

  return (
    <div>
      <div id="wrapper-container">
        <Header />
        <div
          id="main-content"
          style={{ background: "#1e1e1e" }}
          className="pt-5"
        >
          <div className="content-area">
            <div className="site-content layout-1">
              <div className="container">
                <div className="row text-center my-5">
                  <div className="col-12">
                    <h2 className="display-4">BROWSE CREATORS</h2>
                  </div>
                  <div className="col-12 my-3">
                    <input
                      className="form-control"
                      type="search"
                      placeholder="Search creators by username or wallet"
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                    />
                    {isLoading && <p>Loading...</p>}
                    <ul>
                      {results.length > 0 &&
                        results.map((user, index) => (
                          <li key={index} className="my-2 search-hover">
                            <a
                              href={`profile?user=${
                                !user.username ? user.wallet : user.username
                              }`}
                              style={{ color: "#fff" }}
                              target="_blank"
                            >
                              <div className="text-left ml-5">
                                <img
                                  src={user.profile_photo}
                                  alt="Profile"
                                  width="50"
                                  className="mr-2"
                                  style={{ borderRadius: "50%" }}
                                />
                                {!user.username
                                  ? shortenAddress(user.wallet)
                                  : user.username}{" "}
                                {user.verified === "yes" && (
                                  <i
                                    className="fa fa-check-circle"
                                    aria-hidden="true"
                                  />
                                )}
                              </div>
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="row">
                  {creators.length > 0 &&
                    paginatedData.map((c, i) => (
                      <div className="col-md-4">
                        <a
                          href={`profile?user=${
                            !c.username ? c.wallet : c.username
                          }`}
                          style={{ color: "#fff" }}
                          target="_blank"
                        >
                          <div className="profile-card">
                            <img
                              src={c.cover_photo}
                              className="cover-image"
                              alt="Cover Image"
                            />

                            <img
                              src={c.profile_photo}
                              className="avatar"
                              alt="Avatar"
                            />

                            <div className="profile-details">
                              <a href="./profile.html">
                                <h4>
                                  {!c.username
                                    ? shortenAddress(c.wallet)
                                    : c.username}{" "}
                                  {c.verified === "yes" && (
                                    <i
                                      className="fa fa-check-circle"
                                      aria-hidden="true"
                                    />
                                  )}
                                </h4>
                              </a>
                              <div className="d-flex">
                                <span className="p-2">
                                  {" "}
                                  {c.posts_count}{" "}
                                  <i className="fa fa-th" aria-hidden="true" />
                                </span>
                                <span className="p-2">
                                  {" "}
                                  {c.fans_count}{" "}
                                  <i
                                    className="fa fa-heart"
                                    aria-hidden="true"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    ))}
                </div>
                {currentPage * itemsPerPage < creators.length && (
                  <div className="text-center">
                    <button className="btn btn-more" onClick={handleLoadMore}>
                      Load more{" "}
                      <i className="fa fa-chevron-down" aria-hidden="true" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
      <div id="back-to-top" className="btn-back-to-top">
        <i className="ion ion-ios-arrow-thin-up" />
      </div>
    </div>
  );
}

export default Creators;
