import React, { useState, useContext, useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Context } from "../../context/contex";
import {
  formatNumber,
  formatLocalTime,
  shortenAddress,
  getQueryParam,
  getPercentage,
} from "../../utils/helpers";
import axios from "axios";
import $ from "jquery";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";

function SingleVideo() {
  const [video, setVideo] = useState([]);
  const [theVideo, setTheVideo] = useState("");
  const [duration, setDuration] = useState("");

  const [watched, setWatched] = useState(false);

  const [userVideos, setUserVideos] = useState([]);
  const [relatedVideos, setRelatedVideos] = useState([]);

  const {
    address,
    token,
    chainId,
    isConnected,
    walletProvider,
    tokenBalance,
    apiURL,
    settings,
  } = useContext(Context);

  const getVideo = async () => {
    let vid;
    try {
      vid = getQueryParam("vid");
      if (vid == null) return;

      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ action: "get_single_video", vid: vid }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        console.error("Error:", data.error);
      } else {
        setVideo(data[0]);
        setTheVideo(data[0].video.toString());

        console.log("Fetched Video URL:", data[0].video);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    getVideo();
  }, []);

  const handleLoadedMetadata = (event) => {
    const videoElement = event.target;
    const durationInSeconds = videoElement.duration;
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    setDuration(`${minutes}:${seconds < 10 ? "0" : ""}${seconds}`);
  };

  const getUserVideos = async (wallet) => {
    if (!wallet || wallet == undefined) return;
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          action: "get_user_videos",
          wallet: wallet,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        console.error("Error:", data.error);
      } else {
        setUserVideos(data);
        //  console.log("Alive Numbers:", data);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    getUserVideos(video.poster);
  }, [video]);

  const getWatched = async (vid, wallet) => {
    if (!wallet || !vid) return;
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          action: "get_watched",
          vid: vid,
          wallet: wallet,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      setWatched(data.exists);
      //  console.log("Alive Numbers:", data.watched);
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    getWatched(video.id, address);
  }, [video, address]);

  const getRelatedVideos = async (cat) => {
    if (!cat || cat == undefined) return;

    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          action: "get_related_videos",
          category: cat,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        console.error("Error:", data.error);
      } else {
        setRelatedVideos(data);
        //  console.log("Alive Numbers:", data);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    getRelatedVideos(video.category);
  }, [video]);

  const payReward = async (connected, wallet, watched, video) => {
    if (!connected || !wallet || watched || wallet == video.poster) return;

    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          user: wallet,
          vid: video.id,
          action: "pay_watch_reward",
          poster: video.poster,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (!data.success) {
        console.error("Error:", data.message);
        $("#alert-success").empty().hide();
        $("#alert-error").empty().hide();
        $("#alert-error")
          .html(
            `<i class="fa fa-exclamation-circle" aria-hidden="true"></i>

    ${data.message}`
          )
          .show("slow");
      } else {
        $("#alert-success").empty().hide();
        $("#alert-error").empty().hide();
        $("#alert-success")
          .html(
            `<i class="fa fa-check-circle" aria-hidden="true"></i> 
     ${data.message}`
          )
          .show("slow");
        setWatched(true);
        // console.log("Alive Numbers:", data);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const payReward1 = async (connected, wallet, watched, video) => {
    if (!connected || !wallet || watched) return;

    try {
      const response = await axios.post(apiURL, {
        user: wallet,
        vid: video.id,
        action: "pay_watch_reward",
        poster: video.poster,
      });
      if (response.data.success) {
        console.log("Alive Numbers:", response);

        $("#alert-success").empty().hide();
        $("#alert-error").empty().hide();
        $("#alert-success")
          .html(
            `<i class="fa fa-check-circle" aria-hidden="true"></i> 
       ${response.data.message}`
          )
          .show("slow");
      } else {
        console.log("Alive Numbers:", response);

        $("#alert-success").empty().hide();
        $("#alert-error").empty().hide();
        $("#alert-error")
          .html(
            `<i class="fa fa-exclamation-circle" aria-hidden="true"></i>

     ${response.data.message}`
          )
          .show("slow");
      }
    } catch (error) {
      console.error("Error recording watch:", error);
    }
  };

  return (
    <div>
      <div id="wrapper-container">
        <Header />
        <div id="main-content" style={{ background: "#1e1e1e" }}>
          <div className="content-area">
            <div className="page-title">
              <div
                className="main-top"
                style={{
                  backgroundImage: "url(assets/images/bg-page-title.jpg)",
                }}
              >
                <div className="overlay-top-header" />
                <div
                  className="content container pt-5"
                  style={{ marginBottom: "150px" }}
                >
                  <h1 className="mb-3 text-capitalize">{video.title}</h1>
                  {settings && (
                    <h4 className="mb-3 text-capitalize">
                      You will earn{" "}
                      {formatNumber(
                        getPercentage(
                          settings.watch_reward_tokens,
                          settings.viewers_percent
                        )
                      )}{" "}
                      tokens for watching this video if you meet all of the
                      criteria below:
                    </h4>
                  )}
                  <div
                    className="justify-content-center bg-dark rounded d-flex p-2 border border-info"
                    style={{ width: "100% !important" }}
                  >
                    <span className="mr-2">
                      {!isConnected ? (
                        <i
                          className="fa fa-exclamation-circle text-danger"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-check-circle text-success"
                          aria-hidden="true"
                        ></i>
                      )}{" "}
                      Connect wallet
                    </span>{" "}
                    <span className="mr-2">
                      {watched ? (
                        <i
                          className="fa fa-exclamation-circle text-danger"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-check-circle text-success"
                          aria-hidden="true"
                        ></i>
                      )}{" "}
                      Not already watched
                    </span>
                    <span className="mr-2">
                      {isConnected && address == video.poster ? (
                        <i
                          className="fa fa-exclamation-circle text-danger"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-check-circle text-success"
                          aria-hidden="true"
                        ></i>
                      )}{" "}
                      Does not own this video
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="site-content layout-1">
              <div className="container">
                <div className="row">
                  <main className="site-main col-12">
                    <div className="wrap-main-content">
                      <div className="bl-video-detail">
                        <div className="player-video">
                          <div className="bg-video">
                            <div className="video-container">
                              {theVideo && (
                                <video
                                  controls
                                  width="100%"
                                  height="660px"
                                  onLoadedMetadata={handleLoadedMetadata}
                                  onEnded={() =>
                                    payReward(
                                      isConnected,
                                      address,
                                      watched,
                                      video
                                    )
                                  }
                                  onError={(e) =>
                                    console.error("Error loading video:", e)
                                  }
                                >
                                  <source src={theVideo} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </video>
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{ display: "none" }}
                          id="alert-success"
                          className="col-md-12 alert-success p-3 my-4 rounded"
                        ></div>
                        <div
                          style={{ display: "none" }}
                          id="alert-error"
                          className="col-md-12 alert-danger p-3 my-4 rounded"
                        ></div>
                        <div className="detail-video">
                          <div className="info-detail sticky-sidebar">
                            <div className="inner-info">
                              <div className="media-video">
                                <div className="pic-video">
                                  <img
                                    src={video.cover_photo}
                                    alt="IMG"
                                    style={{
                                      height: "400px",
                                      objectFit: "cover",
                                    }}
                                  />
                                </div>
                                {/*}
                                <div className="meta-info">
                                  <div className="imdb">
                                    <span className="value">
                                      {Math.floor(formatNumber(video.views))}
                                    </span>
                                    VIEWS
                                  </div>
                                  <div
                                    className="label"
                                    role="button"
                                    style={{ cursor: "pointer" }}
                                  >
                                    {video.category}
                                  </div>
                                </div>
                                */}
                                <div className="more-info">
                                  {/*
                                  <span className="item-info">
                                    Rating:
                                    <i className="ion ion-android-star" />
                                    8/10
                                  </span>
                                  */}
                                  <span className="item-info">
                                    <i className="ion ion-eye" />
                                    {Math.floor(formatNumber(video.views))}{" "}
                                    Views
                                  </span>
                                </div>
                              </div>
                              <div className="info-video">
                                <div className="item-info">
                                  <span className="name-item">
                                    {" "}
                                    Posted by:{" "}
                                  </span>
                                  <span className="value-item">
                                    {" "}
                                    <a
                                      href={`profile?user=${
                                        !video.username
                                          ? video.poster
                                          : video.username
                                      }`}
                                    >
                                      {!video.username
                                        ? shortenAddress(video.poster)
                                        : video.username}
                                    </a>{" "}
                                  </span>
                                </div>
                                <div className="item-info">
                                  <span className="name-item">
                                    {" "}
                                    Posted on:{" "}
                                  </span>
                                  <span className="value-item">
                                    {" "}
                                    {formatLocalTime(video.post_date)}{" "}
                                  </span>
                                </div>
                                <div className="item-info">
                                  <span className="name-item"> Runtime: </span>
                                  <span className="value-item">
                                    {" "}
                                    {duration} min{" "}
                                  </span>
                                </div>
                                <div className="item-info">
                                  <span className="name-item"> Type: </span>
                                  <span className="value-item">
                                    {" "}
                                    {`${
                                      video.is_vr == "yes" ? "VR" : "Standard"
                                    } video`}{" "}
                                  </span>
                                </div>
                              </div>
                              {/*--   <a
                        href="javascript:;"
                        class="btn-watch btn-normal shape-round"
                      >
                        watch video
                      </a>*/}
                            </div>
                          </div>
                          <div className="content-detail">
                            <div className="field-detail">
                              <h3 className="title-field">Video Description</h3>
                              <div className="content-field">
                                <div>{video.description}</div>
                              </div>
                            </div>
                            <div className="field-detail">
                              <h3 className="title-field">
                                Other Videos by{" "}
                                <a
                                  href={`profile?user=${
                                    !video.username
                                      ? video.poster
                                      : video.username
                                  }`}
                                  style={{ color: "red" }}
                                >
                                  {!video.username
                                    ? shortenAddress(video.poster)
                                    : video.username}
                                </a>
                              </h3>
                              <div className="content-field">
                                <Swiper
                                  navigation={true}
                                  modules={[Pagination, Autoplay, Navigation]}
                                  autoplay
                                  //effect="fade"
                                  pagination={{ clickable: true }}
                                  // slidesPerView={3}
                                  loop={true}
                                  breakpoints={{
                                    640: {
                                      slidesPerView: 1,
                                      spaceBetween: 20,
                                    },
                                    768: {
                                      slidesPerView: 2,
                                      spaceBetween: 30,
                                    },
                                    1024: {
                                      slidesPerView: 2,
                                      spaceBetween: 30,
                                    },
                                  }}
                                  className="my-5"
                                >
                                  {userVideos.length > 0 &&
                                    userVideos.map((v, i) => (
                                      <SwiperSlide key={`p-video${i}`}>
                                        {" "}
                                        <div className="item-slick">
                                          <div className="post-item">
                                            <div className="overlay" />
                                            <div className="pic">
                                              <a
                                                href={`single-video?vid=${v.id}`}
                                                className="btn-play"
                                              >
                                                <img
                                                  src={v.cover_photo}
                                                  alt="IMG"
                                                  style={{
                                                    height: "300px",
                                                    objectFit: "cover",
                                                  }}
                                                />
                                              </a>
                                            </div>
                                            <h4 className="title">
                                              <a
                                                href={`single-video?vid=${v.id}`}
                                                style={{ color: "#eee" }}
                                              >
                                                {v.title}
                                              </a>
                                            </h4>
                                          </div>
                                        </div>
                                      </SwiperSlide>
                                    ))}
                                </Swiper>
                              </div>
                            </div>
                            <div className="entry-tag-share">
                              <div className="tags-links">
                                <span className="name-field">Tags:</span>
                                <a href="#" className="tag-item">
                                  {video.tags}
                                </a>
                              </div>
                            </div>
                            {/*--
                    <div class="comments-area">
                      <div class="list-comments">
                        <h3 class="comments-title">2 comments</h3>
                        <ul class="comment-list">
                          <li class="comment">
                            <img
                              class="avatar"
                              src="assets/images/ava-01.jpg"
                              alt="IMG"
                            />
                            <div class="content-comment">
                              <div class="author">
                                <span class="author-name"> Edugate </span>
                                <span class="comment-extra-info">
                                  November 11, 2016 at 9:10 AM
                                </span>
                                <span class="link-reply-edit">
                                  <a
                                    href="javascript:;"
                                    class="comment-reply-link"
                                  >
                                    Reply
                                  </a>
                                </span>
                              </div>
                              <div class="message">
                                <p>
                                  So far, the conversion increase is more
                                  due to plain blunt force, but the
                                  quality of boss conversions and
                                  subsequent leads can be increased
                                </p>
                              </div>
                            </div>
                            <ul class="children">
                              <li class="comment">
                                <img
                                  class="avatar"
                                  src="assets/images/ava-01.jpg"
                                  alt="IMG"
                                />
                                <div class="content-comment">
                                  <div class="author">
                                    <span class="author-name">
                                      Edugate
                                    </span>
                                    <span class="comment-extra-info">
                                      November 11, 2016 at 9:10 AM
                                    </span>
                                    <span class="link-reply-edit">
                                      <a
                                        href="javascript:;"
                                        class="comment-reply-link"
                                      >
                                        Reply
                                      </a>
                                    </span>
                                  </div>
                                  <div class="message">
                                    <p>
                                      So far, the conversion increase is
                                      more due to plain blunt force, but
                                      the quality of boss conversions and
                                      subsequent leads can be increased
                                    </p>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div> 

                    <div class="form-comment">
                      <div id="respond" class="comment-respond">
                        <h3 id="reply-title" class="comment-reply-title">
                          LEAVE A COMMENT
                          <small>
                            <a href="javascript:;" style="display: none"
                              >Cancel Reply</a
                            >
                          </small>
                        </h3>
                        <form class="comment-form" novalidate>
                          <p class="comment-notes">
                            <span id="email-notes"
                              >Your email address will not be
                              published.</span
                            >
                            Required fields are marked
                            <span class="required">*</span>
                          </p>
                          <div class="comment-meta">
                            <div class="row">
                              <div class="col-md-6">
                                <p class="comment-form-author">
                                  <input
                                    placeholder="Your Name *"
                                    id="author"
                                    name="author"
                                    type="text"
                                    value
                                    size="30"
                                    aria-required="true"
                                  />
                                </p>
                              </div>
                              <div class="col-md-6">
                                <p class="comment-form-email">
                                  <input
                                    placeholder="Email *"
                                    id="email"
                                    name="email"
                                    type="text"
                                    value
                                    size="30"
                                    aria-required="true"
                                  />
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="comment-message">
                            <p class="comment-form-comment">
                              <textarea
                                placeholder="Enter your comment *"
                                id="comment"
                                name="comment"
                                cols="45"
                                rows="8"
                                aria-required="true"
                              ></textarea>
                            </p>
                          </div>
                          <p class="form-submit">
                            <input
                              name="submit"
                              type="submit"
                              id="submit"
                              class="submit"
                              value="submit comment"
                            />
                            <input
                              type="hidden"
                              name="comment_post_ID"
                              value="362"
                              id="comment_post_ID"
                            />
                            <input
                              type="hidden"
                              name="comment_parent"
                              id="comment_parent"
                              value="0"
                            />
                          </p>
                        </form>
                      </div>
                    </div>

                   */}
                            <div className="related-blog">
                              <h3 className="related-title">
                                You May Also Like
                              </h3>
                              <div className="wrap-element">
                                <div className="list-posts">
                                  <Swiper
                                    navigation={true}
                                    modules={[Pagination, Autoplay, Navigation]}
                                    autoplay
                                    //effect="fade"
                                    pagination={{ clickable: true }}
                                    // slidesPerView={3}
                                    loop={true}
                                    breakpoints={{
                                      640: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                      },
                                      768: {
                                        slidesPerView: 2,
                                        spaceBetween: 30,
                                      },
                                      1024: {
                                        slidesPerView: 2,
                                        spaceBetween: 30,
                                      },
                                    }}
                                    className="my-5"
                                  >
                                    {relatedVideos.length > 0 &&
                                      relatedVideos.map((v, i) => (
                                        <SwiperSlide key={`p-video${i}`}>
                                          {" "}
                                          <div className="item-slick">
                                            <div className="post-item">
                                              <div className="pic">
                                                <img
                                                  src={v.cover_photo}
                                                  alt="IMG"
                                                  style={{
                                                    height: "200px",
                                                    objectFit: "cover",
                                                  }}
                                                />
                                                <div className="overlay" />
                                                <div className="meta-info">
                                                  <div className="imdb">
                                                    <span className="value">
                                                      {Math.floor(
                                                        formatNumber(v.views)
                                                      )}
                                                    </span>
                                                    VIEWS
                                                  </div>
                                                  <div
                                                    className="label"
                                                    style={{
                                                      background: "#e40914",
                                                    }}
                                                  >
                                                    {v.category}
                                                  </div>
                                                </div>
                                                <a
                                                  href={`single-video?vid=${v.id}`}
                                                  className="btn-play"
                                                ></a>
                                              </div>
                                              <h4 className="title">
                                                <a
                                                  href={`single-video?vid=${v.id}`}
                                                >
                                                  {v.title}
                                                </a>
                                              </h4>
                                              <div className="info">
                                                {v.tags}
                                              </div>
                                            </div>
                                          </div>
                                        </SwiperSlide>
                                      ))}
                                  </Swiper>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
      <div id="back-to-top" className="btn-back-to-top">
        <i className="ion ion-ios-arrow-thin-up" />
      </div>
    </div>
  );
}

export default SingleVideo;
