// SpinnerOverlay.jsx
import React from "react";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  border-width: 16px;
`;

const SpinnerOverlay = ({ loading }) => {
  if (!loading) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.spinnerContainer}>
        <ClipLoader
          css={override}
          size={150}
          color={"#fff"}
          loading={loading}
        />
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 10000000000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default SpinnerOverlay;
