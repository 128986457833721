import React, { useState, useContext, useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Context } from "../../context/contex";
import {
  formatNumber,
  formatLocalTime,
  shortenAddress,
  getQueryParam,
} from "../../utils/helpers";
import axios from "axios";
import $ from "jquery";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper/modules";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";

import Panorama from "../../components/panorama";

function WatchVR() {
  const [newVideos, setNewVideos] = useState([]);
  const [popularVideos, setPopularVideos] = useState([]);
  const [popularCategories, setPopularCategories] = useState([]);
  const [images, setImages] = useState([
    {
      url: "assets/images/porn1.jpeg",
      link: "https://example.com/page1",
    },
    {
      url: "assets/images/porn2.jpeg",
      link: "https://example.com/page2",
    },
    {
      url: "assets/images/porn3.jpeg",
      link: "https://example.com/page3",
    },
    {
      url: "assets/images/porn-featured.jpeg",
      link: "https://example.com/page4",
    },
    {
      url: "assets/images/category1.jpeg",
      link: "https://example.com/page5",
    },
    {
      url: "assets/images/category2.jpeg",
      link: "https://example.com/page6",
    },
  ]);

  const {
    address,
    token,
    chainId,
    isConnected,
    walletProvider,
    tokenBalance,
    apiURL,
  } = useContext(Context);

  const getNewVideos = async () => {
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ action: "get_new_watch_videos" }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        console.error("Error:", data.error);
      } else {
        setNewVideos(data);
        console.log("Alive Numbers:", data);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    getNewVideos();
  }, []);

  const getPopularVideos = async () => {
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ action: "get_popular_watch_videos" }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        console.error("Error:", data.error);
      } else {
        setPopularVideos(data);
        //  console.log("Alive Numbers:", data);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    getPopularVideos();
  }, []);

  const getPopularCategories = async () => {
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ action: "get_popular_watch_categories" }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        console.error("Error:", data.error);
      } else {
        setPopularCategories(data);
        //  console.log("Alive Numbers:", data);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    getPopularCategories();
  }, []);

  return (
    <div>
      <div id="wrapper-container">
        <Header />
        <div id="main-content" style={{ background: "#1e1e1e" }}>
          <div
            className="thim-banner_home-1"
            style={{ backgroundImage: "url(assets/images/bg-01.jpg)" }}
          >
            <div className="overlay-area" />
            <div className="container-fluid">
              <div className="bp-element bp-element-st-list-videos vblog-layout-1">
                <div className="wrap-element">
                  <div className="feature-item">
                    {/* Slider main container */}

                    <div style={{ height: "100vh" }}>
                      {images.length > 0 ? (
                        <Canvas>
                          <ambientLight />
                          <OrbitControls enableZoom={false} />
                          <Panorama imageUrls={images} />
                        </Canvas>
                      ) : (
                        <p>Loading...</p>
                      )}
                    </div>
                    <Swiper
                      navigation={true}
                      modules={[Pagination, EffectFade, Autoplay, Navigation]}
                      autoplay={{ delay: 4000 }}
                      effect="fade"
                      pagination={{ clickable: true }}
                      slidesPerView={1}
                      centeredSlides={true}
                      fadeEffect={{
                        crossFade: true,
                      }}
                      speed={800}
                    >
                      {newVideos.length > 0 &&
                        newVideos.map((v, i) => (
                          <SwiperSlide key={`video${i}`}>
                            {" "}
                            <div className="row">
                              <div className="col-lg-7">
                                <div className="video">
                                  <img
                                    src={v.cover_photo}
                                    alt="IMG"
                                    style={{
                                      objectFit: "cover",
                                      height: "400px",
                                    }}
                                  />
                                  <div className="overlay" />
                                  <div className="meta-info">
                                    <div className="imdb">
                                      <span className="value">
                                        {formatNumber(v.views)}
                                      </span>
                                      VIEWS
                                    </div>
                                    <div className="label">{v.category}</div>
                                  </div>
                                  <a
                                    href={`single-video?vid=${v.id}`}
                                    className="btn-play"
                                  ></a>
                                </div>
                              </div>
                              <div className="col-lg-5">
                                <div className="text">
                                  <h4 className="title">
                                    <a href={`single-video?vid=${v.id}`}>
                                      {v.title}
                                    </a>
                                  </h4>
                                  <div className="info">
                                    <span className="item-info">
                                      BY{" "}
                                      <a
                                        href={`profile?user=${
                                          !v.username ? v.poster : v.username
                                        }`}
                                      >
                                        {!v.username
                                          ? shortenAddress(v.poster)
                                          : v.username}
                                      </a>
                                    </span>
                                    <span className="item-info">
                                      {formatLocalTime(v.post_date)}
                                    </span>
                                    <span className="item-info">
                                      {v.category}
                                    </span>
                                  </div>
                                  <div className="description">
                                    {v.description}
                                  </div>
                                  <a
                                    href={`single-video?vid=${v.id}`}
                                    className="btn-readmore btn-normal shape-round"
                                  >
                                    Watch &amp; Earn Now
                                  </a>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*POPULAR-*/}

          <div className="thim-popular-video_home-1">
            <div className="container">
              <div className="bp-element bp-element-st-list-videos vblog-layout-slider-1">
                <div className="wrap-element">
                  <div className="heading-post">
                    <h3 className="title">popular videos</h3>
                    {/*
                    <a href="#" className="link">
                      {" "}
                      Regular Videos{" "}
                    </a>
                    <b className="link" style={{ color: "#fff" }}>
                      {" "}
                      |{" "}
                    </b>
                    <a href="#" className="link">
                      {" "}
                      VR Glasses{" "}
                    </a>
                    */}
                  </div>
                  <div className="list-posts">
                    <Swiper
                      navigation={true}
                      modules={[Pagination, Autoplay, Navigation]}
                      autoplay
                      //effect="fade"
                      pagination={{ clickable: true }}
                      // slidesPerView={3}
                      loop={true}
                      breakpoints={{
                        640: {
                          slidesPerView: 1,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 2,
                          spaceBetween: 30,
                        },
                        1024: {
                          slidesPerView: 3,
                          spaceBetween: 30,
                        },
                      }}
                      className="my-5"
                    >
                      {popularVideos.length > 0 &&
                        popularVideos.map((v, i) => (
                          <SwiperSlide key={`p-video${i}`}>
                            {" "}
                            <div className="item-slick">
                              <div className="post-item">
                                <div className="pic">
                                  <img
                                    src={v.cover_photo}
                                    alt="IMG"
                                    style={{
                                      height: "200px",
                                      objectFit: "cover",
                                    }}
                                  />
                                  <div className="overlay" />
                                  <div className="meta-info">
                                    <div className="imdb">
                                      <span className="value">{v.views}</span>
                                      VIEWS
                                    </div>
                                    <div
                                      className="label"
                                      style={{ background: "#e40914" }}
                                    >
                                      {v.category}
                                    </div>
                                  </div>
                                  <a
                                    href={`single-video?vid=${v.id}`}
                                    className="btn-play"
                                  >
                                    {" "}
                                  </a>
                                </div>
                                <h4 className="title">
                                  <a href={`single-video?vid=${v.id}`}>
                                    {v.title}
                                  </a>
                                </h4>
                                <div className="info">{v.tags}</div>
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*ADS*/}
          <div className="thim-ads_home-1">
            <div className="container">
              <div className="bp-element bp-element-call-to-action vblog-layout-1">
                <div
                  className="wrap-element"
                  style={{ backgroundImage: "url(assets/images/ads-01.jpg)" }}
                >
                  <div className="overlay" />
                  <a href="javascript:;" className="content">
                    <div className="text">
                      PORN GAME SHOW Horny Girls Collection Hangout
                    </div>
                    <div className="btn-readmore btn-small shape-round">
                      read more
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/*CATEGORIES*/}
          <div className="thim-trending-categories_home-1">
            <div className="container">
              <div className="bp-element bp-element-st-list-categories vblog-layout-slider-1">
                <div className="wrap-element">
                  <div className="heading-post">
                    <div className="text">
                      <h3 className="title">TRENDING CATEGORIES</h3>
                      <a href="watch-categories" className="link">
                        SEE ALL CATEGORIES
                      </a>
                    </div>
                  </div>
                  <div className="list-posts">
                    <Swiper
                      navigation={true}
                      modules={[Pagination, Autoplay, Navigation]}
                      autoplay
                      //effect="fade"
                      pagination={{ clickable: true }}
                      // slidesPerView={3}
                      loop={true}
                      breakpoints={{
                        640: {
                          slidesPerView: 1,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 2,
                          spaceBetween: 30,
                        },
                        1024: {
                          slidesPerView: 4,
                          spaceBetween: 30,
                        },
                      }}
                      className="my-5"
                    >
                      {popularCategories.length > 0 &&
                        popularCategories.map((c, i) => (
                          <SwiperSlide key={`p-cats${i}`}>
                            {" "}
                            <div className="post-item">
                              <img
                                src={`assets/images/categories/${c.image}`}
                                alt="IMG"
                              />
                              <div className="overlay" />
                              <a
                                href={`categories?cat=${c.category}`}
                                className="content"
                              >
                                <span className="title">{c.category}</span>
                                {Math.floor(formatNumber(c.post_count))}{" "}
                                {c.post_count > 1 ? "Videos" : "Video"}
                              </a>
                            </div>
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*LATEST VIDEOS*/}
          <div className="thim-latest-video_home-1">
            <div className="container">
              <div className="bp-element bp-element-st-list-videos vblog-layout-grid-1">
                <div className="wrap-element">
                  <div className="heading-post">
                    <div className="wrap-title">
                      <h3 className="title">LATEST VIDEOS</h3>
                    </div>
                    {/*}
                    <div className="categories">
                      <ul>
                        <li className="current-cat">
                          <a href="javascript:;"> Regular Videos </a>
                        </li>
                        <li>
                          <a href="javascript:;"> VR Glasses </a>
                        </li>
                      </ul>
                    </div>
                    */}
                  </div>
                  {newVideos.length > 0 && (
                    <>
                      <div className="grid-posts grid-isotope">
                        <div className="row">
                          <div className="col-md-5">
                            <div className="post-item feature-item">
                              <a href={`single-video?vid=${newVideos[0].id}`}>
                                <img src={newVideos[0].cover_photo} alt="IMG" />
                              </a>
                              <div className="overlay" />
                              <div className="meta-info">
                                <div className="imdb">
                                  <span className="value">
                                    {newVideos[0].views}
                                  </span>
                                  VIEWS
                                </div>
                                <div
                                  className="label"
                                  style={{ background: "#ff6c00" }}
                                >
                                  {newVideos[0].category}
                                </div>
                              </div>
                              <div className="content">
                                <h4 className="title">
                                  <a
                                    href={`single-video?vid=${newVideos[0].id}`}
                                  >
                                    {newVideos[0].title}
                                  </a>
                                </h4>
                                <div className="info">
                                  <span>
                                    BY{" "}
                                    <a
                                      href={`profile?user=${
                                        !newVideos[0].username
                                          ? newVideos[0].poster
                                          : newVideos[0].username
                                      }`}
                                    >
                                      {!newVideos[0].username
                                        ? shortenAddress(newVideos[0].poster)
                                        : newVideos[0].username}
                                    </a>
                                  </span>
                                  <span>
                                    {" "}
                                    {formatLocalTime(newVideos[0].post_date)}
                                  </span>
                                </div>

                                <a
                                  href={`single-video?vid=${newVideos[0].id}`}
                                  className="btn-readmore btn-small shape-round"
                                >
                                  Watch &amp; Earn
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-7">
                            <div className="row">
                              {newVideos.slice(1).map((v, i) => (
                                <div key={i} className="col-md-6 my-2">
                                  <div className="post-item">
                                    <a href={`single-video?vid=${v.id}`}>
                                      <img
                                        src={v.cover_photo}
                                        alt="IMG"
                                        style={{
                                          height: "300px",
                                          objectFit: "cover",
                                        }}
                                      />
                                    </a>
                                    <div className="overlay" />
                                    <div className="meta-info">
                                      <div className="imdb">
                                        <span className="value">{v.views}</span>
                                        VIEWS
                                      </div>
                                      <div
                                        className="label"
                                        style={{ background: "#ff6c00" }}
                                      >
                                        {v.category}
                                      </div>
                                    </div>
                                    <div className="content">
                                      <h4 className="title">
                                        <a href={`single-video?vid=${v.id}`}>
                                          {v.title}
                                        </a>
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/*ADS*/}
          <div className="thim-ads_home-1">
            <div className="container">
              <div className="bp-element bp-element-call-to-action vblog-layout-2">
                <div
                  className="wrap-element"
                  style={{ backgroundImage: "url(assets/images/ads-01.jpg)" }}
                >
                  <div className="overlay" />
                  <a href="javascript:;" className="content">
                    <div className="text">
                      THIS IS ANOTHER ADS that leads to no where
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <div id="thim-preloading">
          <div className="custom-image">
            <img src="assets/images/wave.gif" alt="Loading" />
          </div>
        </div>
      </div>
      <div id="back-to-top" className="btn-back-to-top">
        <i className="ion ion-ios-arrow-thin-up" />
      </div>
    </div>
  );
}

export default WatchVR;
