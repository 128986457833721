import React, { useContext, useEffect, useState } from "react";
import { Context } from "../context/contex";
import "./style.css";
import SpinnerOverlay from "./Spinner";

const Header = () => {
  const {
    address,
    token,
    chainId,
    isConnected,
    walletProvider,
    tokenBalance,
    apiURL,
    user,
    getUser,
    loading,
    setLoading,
  } = useContext(Context);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [openSubMenus, setOpenSubMenus] = useState({});

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const toggleSubMenu = (index) => {
    setOpenSubMenus((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const addWallet = async (connection, wallet) => {
    if (!connection || !wallet) return;
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          action: "add_user_wallet",
          wallet: wallet,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (!data.added) {
        console.error("Error:", data.message);
      } else {
        getUser(address);
        //  setRelatedVideos(data);
        console.log("Success:", data.message);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    addWallet(isConnected, address);
  }, [isConnected, address]);

  return (
    <>
      <SpinnerOverlay loading={loading} />

      <header className="site-header sticky-header layout-1">
        <div className="header-container">
          <div className="top-header">
            <div className="container">
              <div className="wrap-content-header">
                <div className="header-logo">
                  <a href="/" className="logo">
                    <img src="assets/images/logo.png" alt="IMG" width={166} />
                  </a>
                  <a href="/" className="mobile-logo">
                    <img src="assets/images/logo.png" alt="IMG" />
                  </a>
                  <a href="/" className="sticky-logo">
                    <img src="assets/images/logo.png" alt="IMG" />
                  </a>
                </div>
                <div className="right-logo">
                  <div className="widget_thim_layout_builder">
                    <div className="wpb_wrapper">
                      <div className="bp-element bp-element-st-search-videos vblog-layout-header-1">
                        <div className="wrap-element">
                          <form className="search-form">
                            <input
                              type="search"
                              className="search-field"
                              name="search"
                              placeholder="Seach for videos..."
                            />
                            <button className="btn-search">
                              <i className="ion ion-android-search" />
                            </button>
                          </form>
                          <ul className="list-search-videos" />
                        </div>
                      </div>
                      <div className="bp-element vblog-layout-header-1">
                        <div className="wrap-element">
                          <w3m-button balance="hide" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bottom-header element-to-stick">
            <div className="container">
              <div className="wrap-content-header">
                <div className="menu-mobile-effect navbar-toggle">
                  <div className="text-menu">Menu</div>
                  <div className="icon-wrap">
                    <i className="ion-navicon" />
                  </div>
                </div>
                <nav className="main-navigation">
                  <ul className="menu-lists">
                    <li className="menu-item-has-children">
                      <a href="/"> Home </a>
                    </li>
                    <li className="menu-item-has-children">
                      <a href="#"> Watch to Earn </a>
                      <ul className="sub-menu">
                        <li>
                          <a href="/watch">Normal Videos </a>
                        </li>
                        <li>
                          <a href="/watch-vr"> VR Videos </a>
                        </li>
                        <li>
                          <a href="/post-video"> Post Video </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item-has-children">
                      <a href="#"> Fans </a>
                      <ul className="sub-menu">
                        <li>
                          <a href="/fan-real"> Real </a>
                        </li>
                        <li>
                          <a href="#"> Ai </a>
                        </li>
                        <li>
                          <a href="/become-creator"> Become A Creator </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item-has-children">
                      <a href="#"> Services </a>
                      <ul className="sub-menu">
                        <li>
                          <a href="/porn-face"> Your porn face </a>
                        </li>
                        <li>
                          <a href="/face-swap"> Face swap </a>
                        </li>
                        <li>
                          <a href="#"> CU Nude </a>
                        </li>
                        <li>
                          <a href="#"> Looks like </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item-has-children">
                      <a href="#"> NFTs </a>
                      <ul className="sub-menu">
                        <li>
                          <a href="#"> Nude collectors NFT</a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item-has-children">
                      <a href="#"> Market </a>
                      <ul className="sub-menu">
                        <li>
                          <a href="#"> Marketplace</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>
                <div className="menu-right">
                  {isConnected && (
                    <div className="bp-element bp-element-button">
                      <a
                        href={`profile?user=${
                          user.username !== null ? user.username : user.wallet
                        }`}
                        className="btn"
                      >
                        <img
                          src={user.profile_photo}
                          height={20}
                          width={20}
                          style={{ borderRadius: "100%", marginRight: "5px" }}
                        />{" "}
                        MY PROFILE
                      </a>
                    </div>
                  )}
                  <div className="bp-element bp-element-button">
                    <a href="#" className="btn">
                      <i className="fa fa-usd" />
                      Buy Tokens
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <nav className="mobile-menu-container mobile-effect">
        <div className="inner-menu">
          <ul className="nav navbar-nav">
            <li className="menu-item-has-children">
              <a href="/"> Home </a>
            </li>
            <li className="menu-item-has-children">
              <a href="#"> Watch to Earn </a>
              <ul className="sub-menu">
                <li>
                  <a href="/watch">Normal Videos </a>
                </li>
                <li>
                  <a href="/watch-vr"> VR Videos </a>
                </li>
                <li>
                  <a href="/post-video"> Post Video </a>
                </li>
              </ul>
            </li>
            <li className="menu-item-has-children">
              <a href="#"> Fans </a>
              <ul className="sub-menu">
                <li>
                  <a href="/fan-real"> Real </a>
                </li>
                <li>
                  <a href="#"> Ai </a>
                </li>
                <li>
                  <a href="/become-creator"> Become A Creator </a>
                </li>
              </ul>
            </li>
            <li className="menu-item-has-children">
              <a href="#"> Services </a>
              <ul className="sub-menu">
                <li>
                  <a href="/porn-face"> Your porn face </a>
                </li>
                <li>
                  <a href="/face-swap"> Face swap </a>
                </li>
                <li>
                  <a href="#"> CU Nude </a>
                </li>
                <li>
                  <a href="#"> Looks like </a>
                </li>
              </ul>
            </li>
            <li className="menu-item-has-children">
              <a href="#"> NFTs </a>
              <ul className="sub-menu">
                <li>
                  <a href="#"> Nude collectors NFT</a>
                </li>
              </ul>
            </li>
            <li className="menu-item-has-children">
              <a href="#"> Market </a>
              <ul className="sub-menu">
                <li>
                  <a href="#"> Marketplace</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Header;
