import React, { useState, useContext, useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Context } from "../../context/contex";
import {
  formatNumber,
  formatLocalTime,
  shortenAddress,
  getQueryParam,
} from "../../utils/helpers";
import axios from "axios";
import $ from "jquery";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper/modules";
import "./fan.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";

function FanReal() {
  const [newCreators, setNewCreators] = useState([]);
  const [popularCreators, setPopularCreators] = useState([]);
  const [topCreators, setTopCreators] = useState([]);

  const {
    address,
    token,
    chainId,
    isConnected,
    walletProvider,
    tokenBalance,
    apiURL,
  } = useContext(Context);

  const getNewCreators = async () => {
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ action: "get_new_creators" }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        console.error("Error:", data.error);
      } else {
        setNewCreators(data);
        // console.log("Alive Numbers:", data);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    getNewCreators();
  }, []);

  const getPopularCreators = async () => {
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ action: "get_popular_creators" }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        console.error("Error:", data.error);
      } else {
        setPopularCreators(data);
        //  console.log("Alive Numbers:", data);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    getPopularCreators();
  }, []);

  const getTopCreators = async () => {
    try {
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ action: "get_top_creators" }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        console.error("Error:", data.error);
      } else {
        setTopCreators(data);
        //  console.log("Alive Numbers:", data);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    getTopCreators();
  }, []);

  return (
    <div>
      <div id="wrapper-container">
        <Header />
        <div id="main-content" style={{ background: "#1e1e1e" }}>
          <div className="content-area">
            <div className="page-title">
              <div
                className="main-top"
                style={{ backgroundImage: "url(assets/images/nft-bg5.jpeg)" }}
              >
                <div
                  className="content container"
                  style={{ paddingBottom: "30px" }}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <h1
                        style={{
                          fontWeight: "700",
                          textTransform: "capitalize",
                        }}
                      >
                        Ready for some private fun &amp; earn?
                      </h1>
                      <div className="description">
                        Explore the best platform where sexy content creators
                        meet their fans. Fans can subscribe and give support to
                        their favourite creators and everyone's on win-win.
                      </div>
                      <div className="row mt-5">
                        <div className="col-sm-6 my-2">
                          <a href="/creators" className="btn btn-custom">
                            EXPLORE CREATORS
                          </a>
                        </div>
                        <div className="col-sm-6 my-2">
                          <a href="/become-creator" className="btn btn-custom">
                            BECOME A CREATORS
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="site-content layout-1">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="description text-center py-5"
                      style={{ fontSize: "20px" }}
                    >
                      Are you a{" "}
                      <span style={{ color: "gold", fontWeight: 700 }}>
                        {" "}
                        CONTENT CREATOR
                      </span>{" "}
                      looking for a way to let your fans support your hard work?
                      We will take care of the rest. An entire platform at your
                      fingertips hasslefree.
                    </div>
                  </div>
                </div>
              </div>
              <section
                className="py-5 px-3 overlay-section"
                style={{
                  backgroundImage: 'url("assets/images/fans/how-bg.jpeg")',
                  backgroundRepeat: "no-repeat",
                  backgroundAttachment: "fixed",
                  backgroundSize: "cover",
                }}
              >
                <div className="content">
                  <div className="row d-flex align-items-center">
                    <div className="col-md-6">
                      <img src="assets/images/fans/midpic1.png" width={350} />
                    </div>
                    <div className="col-md-6">
                      <h1 style={{ fontSize: "30px", fontWeight: 700 }}>
                        How it works for Creators
                      </h1>
                      <div className="description">
                        You create and upload hot private contents on your
                        profile. Your fans decide to reward you for your hard
                        work by paying a fee to gain access to your contents. In
                        exchange, you keep doing what you love &amp; also offer
                        them some hot perks.
                      </div>
                      <div className="row mt-5">
                        <div className="col-sm-12">
                          <a href="/become-creator" className="btn btn-custom">
                            BECOME A CREATORS
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row my-5 d-flex align-items-center">
                    <div className="col-md-6">
                      <h1 style={{ fontSize: "30px", fontWeight: 700 }}>
                        How it works for Fans
                      </h1>
                      <div className="description">
                        You create and upload hot private contents on your
                        profile. Your fans decide to reward you for your hard
                        work by paying a fee to gain access to your contents. In
                        exchange, you keep doing what you love &amp; also offer
                        them some hot perks.
                      </div>
                      <div className="row mt-5">
                        <div className="col-sm-12">
                          <a href="/creators" className="btn btn-custom">
                            EXPLORE CREATORS
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <img src="assets/images/fans/midpic2.png" width={350} />
                    </div>
                  </div>
                  <div className="row my-5">
                    <div className="col-12 text-center">
                      <h1 className="display-4">TOP CREATORS</h1>
                    </div>
                  </div>

                  <Swiper
                    navigation={true}
                    modules={[Pagination, Autoplay, Navigation]}
                    autoplay
                    //effect="fade"
                    pagination={{ clickable: true }}
                    // slidesPerView={3}
                    loop={true}
                    breakpoints={{
                      640: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                      },
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                      },
                      1024: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                      },
                    }}
                    className="my-5"
                  >
                    {topCreators.length > 0 &&
                      topCreators.map((v, i) => (
                        <SwiperSlide key={`p-video${i}`}>
                          {" "}
                          <div
                            className="swiper-slide"
                            onClick={() =>
                              window.open(
                                `/profile?user=${
                                  !v.username ? v.wallet : v.username
                                }`,
                                "_blank",
                                "noopener,noreferrer"
                              )
                            }
                          >
                            <div className="profile-card">
                              <img
                                src={v.cover_photo}
                                className="cover-image"
                                alt="Cover Image"
                              />
                              <img
                                src={v.profile_photo}
                                className="avatar"
                                alt="Avatar"
                              />
                              <div className="profile-details">
                                <h4>
                                  {!v.username
                                    ? shortenAddress(v.wallet)
                                    : v.username}{" "}
                                  {v.verified === "yes" && (
                                    <i
                                      className="fa fa-check-circle"
                                      aria-hidden="true"
                                    />
                                  )}
                                </h4>
                                <p>
                                  {v.posts_count} Posts{" "}
                                  <i className="fa fa-th" aria-hidden="true" />
                                </p>
                                <p>
                                  {v.fans_count} Fans{" "}
                                  <i
                                    className="fa fa-heart"
                                    aria-hidden="true"
                                  />
                                </p>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                  </Swiper>

                  <div className="row my-5">
                    <div className="col-12 text-center">
                      <h1 className="display-4">POPULAR CREATORS</h1>
                    </div>
                  </div>
                  <Swiper
                    navigation={true}
                    modules={[Pagination, Autoplay, Navigation]}
                    autoplay
                    //effect="fade"
                    pagination={{ clickable: true }}
                    // slidesPerView={3}
                    loop={true}
                    breakpoints={{
                      640: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                      },
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                      },
                      1024: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                      },
                    }}
                    className="my-5"
                  >
                    {popularCreators.length > 0 &&
                      popularCreators.map((v, i) => (
                        <SwiperSlide key={`p-video${i}`}>
                          {" "}
                          <div
                            className="swiper-slide"
                            onClick={() =>
                              window.open(
                                `/profile?user=${
                                  !v.username ? v.wallet : v.username
                                }`,
                                "_blank",
                                "noopener,noreferrer"
                              )
                            }
                          >
                            <div className="profile-card">
                              <img
                                src={v.cover_photo}
                                className="cover-image"
                                alt="Cover Image"
                              />
                              <img
                                src={v.profile_photo}
                                className="avatar"
                                alt="Avatar"
                              />
                              <div className="profile-details">
                                <h4>
                                  {!v.username
                                    ? shortenAddress(v.wallet)
                                    : v.username}{" "}
                                  {v.verified === "yes" && (
                                    <i
                                      className="fa fa-check-circle"
                                      aria-hidden="true"
                                    />
                                  )}
                                </h4>
                                <p>
                                  {v.posts_count} Posts{" "}
                                  <i className="fa fa-th" aria-hidden="true" />
                                </p>
                                <p>
                                  {v.fans_count} Fans{" "}
                                  <i
                                    className="fa fa-heart"
                                    aria-hidden="true"
                                  />
                                </p>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                  </Swiper>
                  <div className="row my-5">
                    <div className="col-12 text-center">
                      <h1 className="display-4">NEW CREATORS</h1>
                    </div>
                  </div>
                  <Swiper
                    navigation={true}
                    modules={[Pagination, Autoplay, Navigation]}
                    autoplay
                    //effect="fade"
                    pagination={{ clickable: true }}
                    // slidesPerView={3}
                    loop={true}
                    breakpoints={{
                      640: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                      },
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                      },
                      1024: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                      },
                    }}
                    className="my-5"
                  >
                    {newCreators.length > 0 &&
                      newCreators.map((v, i) => (
                        <SwiperSlide key={`p-video${i}`}>
                          {" "}
                          <div
                            className="swiper-slide"
                            onClick={() =>
                              window.open(
                                `/profile?user=${
                                  !v.username ? v.wallet : v.username
                                }`,
                                "_blank",
                                "noopener,noreferrer"
                              )
                            }
                          >
                            <div className="profile-card">
                              <img
                                src={v.cover_photo}
                                className="cover-image"
                                alt="Cover Image"
                              />
                              <img
                                src={v.profile_photo}
                                className="avatar"
                                alt="Avatar"
                              />
                              <div className="profile-details">
                                <h4>
                                  {!v.username
                                    ? shortenAddress(v.wallet)
                                    : v.username}{" "}
                                  {v.verified === "yes" && (
                                    <i
                                      className="fa fa-check-circle"
                                      aria-hidden="true"
                                    />
                                  )}
                                </h4>
                                <p>
                                  {v.posts_count} Posts{" "}
                                  <i className="fa fa-th" aria-hidden="true" />
                                </p>
                                <p>
                                  {v.fans_count} Fans{" "}
                                  <i
                                    className="fa fa-heart"
                                    aria-hidden="true"
                                  />
                                </p>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </div>
              </section>
            </div>
          </div>
        </div>

        <Footer />
      </div>
      <div id="back-to-top" className="btn-back-to-top">
        <i className="ion ion-ios-arrow-thin-up" />
      </div>
    </div>
  );
}

export default FanReal;
